.wrap {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background-color: #fff;
}
.wrap .content {
  max-width: 98.9rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
}
.wrap .content table {
  height: auto;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.wrap .content table td,
.wrap .content table th {
  border: 1px solid #595959;
  padding: 5px;
}
.wrap .content table th {
  background: #347c99;
  color: #fff;
}
.wrap .content table td {
  text-align: start;
  vertical-align: text-top;
}
.p1 {
  margin-top: 11.6rem;
}
.button {
  width: 21rem;
  height: 4.8rem;
  border-radius: 0.4rem;
  align-self: flex-start;
  margin: 0;
}
.video {
  margin-bottom: 6.4rem;
  height: 50rem;
}
.buttonText {
  font-size: 1.8rem;
  line-height: 2.2rem;
}
@media only screen and (max-width: 736px) {
  .wrap .content {
    width: 86%;
  }
  .p1 {
    margin-top: 7.5rem;
  }
  .button {
    width: 100%;
  }
  .video {
    height: 25rem;
  }
}
@media only screen and (min-width: 737px) and (max-width: 1023px) {
  .wrap .content {
    width: 80%;
  }
  .p1 {
    margin-top: 7.5rem;
  }
  .video {
    height: 40rem;
  }
}
/*# sourceMappingURL=app/Pages/Codex/Standards/index.css.map */