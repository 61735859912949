.icons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(216,216,216,0.16);
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-top: 2rem;
}
.icons .iconWrap {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  max-width: 5.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  font-family: 'Roboto Medium', sans-serif;
  font-size: 0.9rem;
  line-height: 1.1rem;
}
.icons .iconWrap .icon {
  width: 2.7rem;
  height: 2.7rem;
  min-height: 2.2rem;
}
.icons .iconWrap .icon use,
.icons .iconWrap .icon path,
.icons .iconWrap .icon polygon {
  fill: #d5d5d5;
}
.icons .iconWrap .icon.highlight use,
.icons .iconWrap .icon.highlight path,
.icons .iconWrap .icon.highlight polygon {
  fill: #f5a623;
}
.icons .iconWrap .icon.transparent use,
.icons .iconWrap .icon.transparent path,
.icons .iconWrap .icon.transparent polygon {
  fill: none;
}
.icons .iconWrap span {
  margin-top: 0.6rem;
}
/*# sourceMappingURL=app/Components/OrderStatusIcons/index.css.map */