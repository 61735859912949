
.icons
  display flex
  flex-flow row wrap
  justify-content space-between
  align-items center
  background-color $paleGray
  padding-left x(12)
  padding-right x(12)
  margin-left - x($paddingLeft)
  margin-right - x($paddingLeft)
  margin-top x(20)

  .iconWrap
    display flex
    flex-flow column nowrap
    align-items center
    text-align center
    max-width x(55)
    padding-top x(8)
    padding-bottom @padding-top
    font-family $robotoMedium
    font-size x(9)
    line-height x(11)

    .icon
      svg-fill($paleIconGray)
      width x(27)
      height x(27)
      min-height x(22)

      &.highlight
        svg-fill($accentOrange)

      &.transparent
        svg-fill(none)

    span
      margin-top x(6)