.detailsButton {
  margin: 3.2rem auto;
  max-width: 29.7rem;
  height: 5.7rem;
  width: 100%;
  border-radius: 1.4rem;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 2rem;
  line-height: 2.4rem;
}
.detailsContent {
  color: #f5a623;
}
.detailsIcon {
  margin-right: -1rem;
}
.detailsIcon use,
.detailsIcon path,
.detailsIcon polygon {
  fill: #f5a623;
}
.detailsSection {
  display: flex;
  flex-flow: column;
  margin-bottom: 5rem;
}
.infoBlock {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #191919;
  margin-bottom: 2.2rem;
  padding-bottom: 0.6rem;
  border-bottom: 0.2rem solid #ddd;
}
.description {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.4rem;
  line-height: 1.7rem;
  margin-bottom: 2.8rem;
  white-space: pre-line;
}
.attention {
  margin-bottom: 3.7rem;
}
.attachment {
  margin-bottom: 3rem;
}
.dropdownIcon {
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
}
.callButton {
  max-width: 29.3rem;
  width: 100%;
  height: 100%;
  border-radius: 1.4rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 1.3rem 2rem;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 2rem;
  line-height: 2.4rem;
}
.callButton .callIcon {
  margin-right: 1.3rem;
}
.callButton .callIcon use,
.callButton .callIcon path,
.callButton .callIcon polygon {
  fill: #fff;
}
.callButton .callIcon use,
.callButton .callIcon path,
.callButton .callIcon polygon {
  stroke: #fff;
}
.callButton .callButtonText {
  flex-grow: 1;
  text-align: left;
}
.callButton + .callButton {
  margin-top: 1.4rem;
}
.closed {
  display: none;
}
/*# sourceMappingURL=app/Components/OrderDetails/index.css.map */