.wideInputWrap,
.wideInput {
  width: 100%;
  min-width: 0rem;
}
.input {
  min-width: 0rem;
  max-width: 6.5rem;
  width: auto;
}
.error {
  font-size: 1.2rem;
  color: #f5a623;
  margin-top: 0rem;
}
.errorSms {
  color: #d0021b;
  margin-top: 1rem;
  display: block;
}
.hint {
  text-align: center;
  color: #4a4a4a;
  margin-top: 0.4rem;
  margin-bottom: 3.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.code {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin-top: 4rem;
}
.code .codeLabel {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #191919;
}
.code .codeWrap,
.code .codeInput {
  width: 14rem;
  text-align: center;
}
.code .codeInput {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 2.9rem;
  color: #191919;
  border-bottom-width: 0.2rem;
}
.privacyCheckbox {
  margin-top: 4rem;
}
.privacyLink {
  border-radius: 0rem;
  border-bottom: 0.1rem dashed #979797;
}
.sendCodeButton,
.callMasterButton {
  font-family: 'Roboto Regular', sans-serif;
  color: #fff;
  font-size: 2rem;
  line-height: 2.4rem;
  max-width: 29.3rem;
  width: 100%;
  height: 6rem;
  border-radius: 1.4rem;
  margin-top: 5.4rem;
}
.sendCodeButton {
  margin-top: 3rem;
}
.callMasterButton {
  margin-bottom: 0rem;
  margin-top: 0rem;
  width: 100%;
  max-width: 29.3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.attention {
  margin-top: 2.7rem;
  margin-bottom: 5rem;
}
.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  font-family: 'Roboto Light', sans-serif;
  font-weight: 300;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #191919;
  margin-top: 5rem;
  margin-bottom: 4rem;
}
.headerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5a623;
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  margin-right: 1.2rem;
  border-radius: 2.5rem;
}
.headerIcon svg use,
.headerIcon svg path,
.headerIcon svg polygon {
  fill: #fff;
}
.link {
  text-decoration: none;
  font-size: 2rem;
  text-align: center;
  border-radius: 0rem;
}
.mainPage {
  display: flex;
  justify-content: center;
}
.mainPage a {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 2rem;
  color: #63b896;
  padding-bottom: 0.2rem;
  border-bottom-width: 0.2rem;
  margin-bottom: 5rem;
}
/*# sourceMappingURL=app/Components/AuthorizationSms/index.css.map */