.container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.input {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 1px 2px 3px;
  opacity: 0;
  overflow: hidden;
  width: 19px;
  height: 19px;
  cursor: pointer;
}
.input:disabled {
  cursor: default;
}
.input:disabled + .iconWrap {
  opacity: 0.3;
}
.input:disabled ~ .label {
  cursor: default;
  opacity: 0.3;
}
.input:focus + .iconWrap {
  opacity: 1;
  box-shadow: 0 0 1px 1xp #ddd;
}
.input:checked + .iconWrap .icon {
  opacity: 1;
}
.iconWrap {
  pointer-events: none;
  position: absolute;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #9b9b9b;
  border-radius: 2px;
}
.iconWrapError {
  border: 2px solid #d0021b;
}
.icon {
  opacity: 0;
}
.icon use,
.icon path,
.icon polygon {
  fill: #239a6a;
}
.label {
  cursor: pointer;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 14px;
  color: #191919;
  line-height: 16px;
  margin-left: 14px;
}
/*# sourceMappingURL=app/Elements/Checkbox/index.css.map */