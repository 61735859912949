.nav
  width: 100%
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  border-top 1px solid $black
  padding x(12) 0
  font-size x(14)

.wrap
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  border-radius: x(3)
  height: x(26)
  background-color: rgba(0, 48, 265, .1)

.pagination
  padding 0
  margin 0
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  list-style: none
  line-height: x(26)

.page, .pagePlaceholder
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  text-decoration: none
  padding 0 x(8)


.page
  cursor: pointer

  &:hover, &:active
    text-decoration: none
/*    color: $light

    use
      fill: $light

  &:hover
    background-color: $hover

  &:active
    background-color: $active*/

.currentPage
  pointer-events: none

.currentPage
  background-color: $white

.pageLeft, .pageRight
  padding 0
