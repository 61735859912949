.wrapper
  text-align center
  padding-top x(72)

.icon
  svg-fill($accentOrange)
  margin-bottom x(30)
  display inline-block

.title
  margin 0 0 x(20)

  span
    display block

  &Heading
    font-size x(48)
    font-weight 600
    line-height x(30)
    margin-bottom x(45)

  &Desc
    font-weight 500

.desc
  display block
  font-size x(16)
  line-height x(22)
