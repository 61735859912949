
.image
  display flex
  height 100%
  background-size 300%
  background-repeat repeat
  background-position center

.image:not([src])
  content url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')

