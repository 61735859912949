
.wrap
  display flex
  flex-flow row nowrap
  align-items center
  width auto
  margin-top x(0)
  margin-bottom x(0)

.iconCircle
  display flex
  justify-content center
  align-items center
  width x(30)
  height x(30)
  min-width x(30)
  background-color $accentOrange
  border-radius x(20)
  margin-right x(12)

  .icon
    svg-fill($white)

.icon
  svg-fill($black)
  height x(16)
  width (16)
  min-width x(16)

.labelRequired
  font-family $robotoRegular
  font-size x(14)
  color $errorRed
  display inline
  line-height x(19);
  font-weight 300
  margin-left x(7)
