
.textless
  display -webkit-box
  overflow hidden
  //-webkit-line-clamp 3
  -webkit-box-orient vertical
  -moz-box-orient vertical
  text-overflow ellipsis
  margin 0
  /* autoprefixer: off */

.textmore
  margin 0

.toggle
  margin 0
  padding 0
  border 0 !important

  .toggleContent
    display block
    color black !important
    font-weight bold
    width revert
    height revert
