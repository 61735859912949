$grey = rgba(220,231,235,1)
$empty = ()

.transition
  transition all 0.25s ease-in-out

.flipIn
  animation flipDown 0.5s ease both

.accordion
  position relative
  padding 0
  margin 0
  padding-bottom 4px
  padding-top 18px
  border-top 1px dotted $grey
  @extend .flipIn

  &:nth-of-type(1)
    padding-top 0
    animation-delay 0.5s
  &:nth-of-type(2)
    animation-delay 0.75s
  &:nth-of-type(3)
    animation-delay 1.0s
  &:last-of-type
    padding-bottom 0

  i
    position absolute
    transform translate( -6px , 0 )
    margin-top 16px
    right 0

    &:before, &:after
      content ""
      @extend .transition
      position absolute
      background-color $accentOrange
      width 3px
      height 9px

    &:before
      transform translate( -2px , 0 ) rotate( 45deg )

    &:after
      transform translate( 2px , 0 ) rotate( -45deg )

  input[type=checkbox]
    position absolute
    cursor pointer
    width 100%
    height 100%
    z-index 1
    opacity 0

    &:checked
      &~.content
        margin-top 0
        max-height 0
        opacity 0
        transform translate( 0 , 50% )

      &~i
        &:before
          transform translate( 2px , 0 ) rotate( 45deg )

        &:after
          transform translate( -2px , 0 ) rotate( -45deg )

  .label
    //font-size x(14)
    line-height x(42) // 4.2rem
    font-weight: 300
    letter-spacing: 1px
    display: block
    margin: 0
    cursor: pointer
  .content
    //@extend .transition
    $empty

.accordionGroup
  list-style none
  perspective 900
  padding 0
  margin 0

@keyframes flipDown
  0%
    opacity 0
    transform-origin top center
    transform rotateX(-90deg)
  5%
    opacity 1
  80%
    transform rotateX(8deg)
  83%
    transform rotateX(6deg)
  92%
    transform rotateX(-3deg)
  100%
    transform-origin top center
    transform rotateX(0deg)

