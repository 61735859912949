.main {
  width: 100%;
  position: relative;
}
.input {
  width: 100%;
  font-family: 'Roboto Light', sans-serif;
  font-size: 1.4rem;
  line-height: 1.9rem;
  border: none;
  border-bottom: 0.1rem solid #ddd;
}
.inputError {
  border-bottom: 0.2rem solid #d0021b;
}
.inputDisabled {
  color: #a0a0a0;
}
.textarea {
  border: 0.1rem solid #ddd;
}
.textareaError {
  border: 0.2rem solid #d0021b;
}
.label {
  width: 100%;
  font-family: 'Roboto Light', sans-serif;
  font-size: 1.4rem;
  color: #191919;
  margin-top: 0.2rem;
}
.labelError {
  font-family: 'Roboto Regular', sans-serif;
  color: #d0021b;
}
.hint {
  font-family: 'Roboto Regular', sans-serif;
  color: #9b9b9b;
  margin-top: 0rem;
  font-size: 1.2rem;
}
.error {
  font-size: 1.2rem;
  color: #f5a623;
  margin-top: 0rem;
}
.labelRequired {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.4rem;
  color: #d0021b;
  display: inline;
  line-height: 1.9rem;
  font-weight: 300;
  margin-right: 0.4rem;
}
/*# sourceMappingURL=app/Elements/Input/index.css.map */