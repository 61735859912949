.button {
  position: relative;
  display: block;
  border-radius: 0.8rem;
  background-color: transparent;
  min-width: 12rem;
  box-sizing: border-box;
  text-decoration: none;
  transition: 0.15s ease-in-out;
  border: 0.1rem solid #fff;
  outline: none;
  margin: 0;
  padding: 0;
  text-transform: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  cursor: pointer;
  padding-left: 2.7rem;
  padding-right: 2.7rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.button.orange {
  background-color: #f5a623;
  color: #fff;
}
.button.orange:hover {
  background-color: #ffc86e;
  color: #fff;
}
.button.orange:active {
  background-color: #ff9200;
  color: #fff;
}
.button.green {
  background-color: #63b896;
  color: #fff;
}
.button.green:hover {
  background-color: #6ac0ae;
  color: #fff;
}
.button.green:active {
  background-color: #239a6a;
  color: #fff;
}
.button.transparent {
  background-color: #fff;
  border: 0.2rem solid #f5a623;
  color: #000;
}
.button:disabled,
.button:disabled:hover,
.button:disabled:active {
  cursor: default;
  pointer-events: none;
  opacity: 0.7;
  background-color: #979797;
}
.x36 {
  height: 3.6rem;
  min-height: 3.6rem;
}
.x40 {
  height: 4rem;
  min-height: 3.6rem;
}
.buttonFocus:focus {
  box-shadow: 0 0 0.1rem 0.1rem $gray;
}
.content {
  opacity: 1;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: transparent;
}
.loader {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.buttonBusy {
  cursor: progress !important;
}
.buttonBusy .loader {
  opacity: 1;
}
.buttonBusy .content {
  opacity: 0;
}
.content,
.loader {
  transition: opacity 0.3s ease-in;
}
/*# sourceMappingURL=app/Elements/Button/index.css.map */