.modalBack {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(160,160,160,0.5);
  animation: background-color 0.2s 1 ease-in-out;
  animation-fill-mode: forwards;
}
.modal {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -17%);
  opacity: 1;
  background-color: #fff;
  border-radius: 1.2rem;
  box-shadow: 0 0 1rem 0 rgba(57,101,113,0.2), 0 0.1rem 0.1rem 0 rgba(57,101,113,0.05);
}
.iconContainer {
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  opacity: 1;
  transition: opacity ease 0.2s;
  background-color: #000;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 1.5rem;
  display: grid;
  justify-content: center;
  align-items: center;
}
.iconContainer:hover {
  opacity: 0.6;
}
.iconStyle use,
.iconStyle path,
.iconStyle polygon {
  fill: #fff;
}
.title {
  font-family: 'Roboto Light', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.9rem;
  color: #191919;
  margin-bottom: 0;
  margin-left: 3rem;
  margin-right: 6.5rem;
  margin-top: 2rem;
}
@-moz-keyframes background-color {
  from {
    background-color: rgba(160,160,160,0);
  }
  to {
    background-color: rgba(160,160,160,0.5);
  }
}
@-webkit-keyframes background-color {
  from {
    background-color: rgba(160,160,160,0);
  }
  to {
    background-color: rgba(160,160,160,0.5);
  }
}
@-o-keyframes background-color {
  from {
    background-color: rgba(160,160,160,0);
  }
  to {
    background-color: rgba(160,160,160,0.5);
  }
}
@keyframes background-color {
  from {
    background-color: rgba(160,160,160,0);
  }
  to {
    background-color: rgba(160,160,160,0.5);
  }
}
/*# sourceMappingURL=app/Layout/modal.css.map */