
.main
  width 100%
  position relative

.input
  width 100%
  font-family $robotoLight
  font-size x(14)
  line-height x(19)
  border none
  border-bottom x(1) solid $strokeGray

.inputError
  border-bottom x(2) solid $errorRed

.inputDisabled
  color $modalGray

.textarea
  border x(1) solid $strokeGray

.textareaError
  border x(2) solid $errorRed

.label
  width 100%
  font-family $robotoLight
  font-size x(14)
  color $textGray
  margin-top x(2)

.labelError
  font-family $robotoRegular
  color $errorRed

.hint
  font-family $robotoRegular
  color $hintGray
  margin-top x(0)
  font-size x(12)

.error
  font-size x(12)
  color $accentOrange
  margin-top x(0)

.labelRequired
  font-family $robotoRegular
  font-size x(14)
  color $errorRed
  display inline
  line-height x(19);
  font-weight 300
  margin-right x(4)

