.review {
  display: flex;
  justify-content: start;
  align-items: start;
}
.review .header {
  display: flex;
  justify-content: start;
  align-items: start;
  font-family: 'Roboto Light', sans-serif;
  padding: 1rem 0 0 0;
}
.review .content {
  font-family: 'Roboto Light', sans-serif;
  font-size: 1.4rem;
  padding: 1rem 0 0 2rem;
}
.review .content .contentHeader {
  display: flex;
  justify-content: start;
  align-items: center;
}
.review .content .contentText {
  margin: 1rem 0;
}
.avatarIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  min-width: 8rem;
  background-color: #f5a623;
  border: 0.2rem solid #f5a623;
  border-radius: 5rem;
}
.avatar {
  width: 8rem;
  height: 8rem;
  min-width: 8rem;
  border-radius: 5rem;
}
.highlight {
  font-family: 'Roboto Medium', sans-serif;
  font-weight: bold;
}
.icon {
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
}
.icon use,
.icon path,
.icon polygon {
  fill: #fff;
}
.title {
  margin: 0 5px 0 0;
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 736px) {
  .review .content {
    padding-left: 1rem;
  }
  .review .content .contentHeader {
    flex-direction: column;
    align-items: start;
  }
  .avatarIcon {
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
  }
  .avatar {
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
  }
  .icon {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
  }
}
/*# sourceMappingURL=app/Components/ReviewFeed/index.css.map */