.dateSelectorInputs {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2rem;
  margin-bottom: 3.5rem;
}
.dateSelectorInputs .dateInput,
.dateSelectorInputs .timeInput {
  width: auto;
  margin-top: 0.6rem;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.4rem;
  color: #000;
  font-style: normal;
}
.dateSelectorInputs .dateInput {
  max-width: 9rem;
  margin-right: 1.2rem;
}
.dateSelectorInputs .timeInput {
  max-width: 4.8rem;
}
.dateSelectorInputs .divInputData {
  max-width: 9rem;
  margin-right: 5.8rem;
}
.dateSelectorInputs .divInputTime {
  max-width: 4.8rem;
}
/*# sourceMappingURL=app/Elements/DateSelector/index.css.map */