.wrap
  display flex
  flex-direction column
  align-items stretch
  justify-content flex-start
  height 100%
  background-color white

  .content
    max-width x(989)
    margin 0 auto
    display flex
    flex-direction column
    align-items stretch
    justify-content flex-start
    flex-grow 1

    +media('phone')
      width 86%

    +media('tablet')
      width 80%

.p1
  margin-top x(116)
  margin-bottom 0
  text-align center

  +media('phone')
    margin-top x(75)

  +media('tablet')
    margin-top x(75)

.form
  :global(.react-datepicker-wrapper)
    display block

  :global(.react-datepicker__input-container)
    display block

  :global(.react-datepicker__input-container)
    margin-top x(5)

  margin-top x(27)
  margin-bottom x(27)
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  +media('tablet')
    flex-direction: column;

  +media('phone')
    flex-direction: column;

  .inputLabel
    font-size x(16)
    margin-left x(7)

  .inputError
    color $errorRed
    font-size x(14)
    font-weight bold
    margin-left x(2)
    margin-top x(2)
    white-space: pre-wrap;

  .inputRow
    margin-bottom x(29)

  .inputColumn
    width 45%
    margin-bottom x(29)
    +media('tablet')
      width 100%
      margin 0 !important

    +media('phone')
      width 100%
      margin 0 !important

  .inputColumn:nth-child(odd)
    margin-right 2.5%

  .inputColumn:nth-child(even)
    margin-left 2.5%

  .inputWrap
    margin-top x(5)
    display inline-flex

  .input
    border x(1) solid #D8D8D8
    border-radius x(4)
    height x(45)
    padding x(7)
    font-family $robotoRegular
    font-size x(16)
    line-height x(31)

  .textarea
    border x(1) solid #D8D8D8
    border-radius x(4)
    padding x(7)
    font-family $robotoRegular
    font-size x(16)
    resize none

  .radioButtons
    display flex
    margin-left x(7)
    margin-top x(9)

    label
      margin-right x(18)

  .inputTitle
    margin-left x(7)
    margin-bottom x(5)
    font-size x(16)

  .isAgreeCheckbox
    justify-content flex-start
    align-items center

    > div, > input
      flex-grow 0

    > div
      top 1px
      left 1px

    > label
      flex 1
      margin-left x(11)


  .button
    width x(198)
    height x(48)
    border-radius x(4)
    align-self flex-start
    margin 0

    +media('phone')
      width 100%

    +media('tablet')
      width 100%

  .buttonText
    font-size x(18)
    line-height x(22)

.ageMessage
  margin-top x(20)
  text-align center
  margin-bottom x(28)

.ageButton
  margin-bottom x(18)

applyMediaCache()
