
.review
  display flex
  justify-content start
  align-items start

  .header
    display flex
    justify-content start
    align-items start
    font-family $robotoLight
    padding x(10) 0 0 0

  .content
    font-family $robotoLight
    font-size x(14)
    padding x(10) 0 0 x(20)

    +media('phone')
      padding-left x(10)

    .contentHeader
      display flex
      justify-content start
      align-items center

      +media('phone')
        flex-direction column
        align-items start

    .contentText
      margin x(10) 0

.avatarIcon
  flexCenter()
  width x(80)
  height x(80)
  min-width x(80)
  background-color $accentOrange
  border x(2) solid $accentOrange
  border-radius x(50)

  +media('phone')
    width x(40)
    height x(40)
    min-width x(40)

.avatar
  width x(80)
  height x(80)
  min-width x(80)
  border-radius x(50)

  +media('phone')
    width x(40)
    height x(40)
    min-width x(40)

.highlight
  font-family $robotoMedium
  font-weight bold

.icon
  svg-fill($white)
  width x(40)
  height x(40)
  min-width x(40)

  +media('phone')
    width x(20)
    height x(20)
    min-width x(20)

.title
  margin 0 5px 0 0
  textBreak()

.loading
  flexCenter()

applyMediaCache()
