.dateSelectorInputs
  display flex
  flex-flow row wrap
  margin-top x(20)
  margin-bottom x(35)

  .dateInput
  .timeInput
    width auto
    margin-top x(6)
    font-family $robotoRegular
    font-size x(14)
    color $black
    font-style: normal

  .dateInput
    max-width x(90)
    margin-right x(12)

  .timeInput
    max-width x(48)

  .divInputData
    max-width x(90)
    margin-right x(58)
  
  .divInputTime
    max-width x(48)
