.pageWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.okIcon {
  margin-top: 4.6rem;
  margin-bottom: 2.7rem;
}
.okIcon use,
.okIcon path,
.okIcon polygon {
  fill: #63b896;
}
.okIcon use,
.okIcon path,
.okIcon polygon {
  stroke: #63b896;
}
.textCloseOrder {
  font-family: 'Roboto Regular', sans-serif;
  margin-top: 0rem;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 3rem;
  color: #000;
  padding-right: 3.5rem;
  padding-left: 3.5rem;
}
.textCloseOrderData {
  margin-top: 2.2rem;
}
.ratingButton {
  max-width: 29.3rem;
  width: 100%;
  height: 5.9rem;
  border-radius: 1.4rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 1.3rem 2rem;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 2rem;
  line-height: 2.4rem;
  margin-top: 2.9rem;
  margin-bottom: 2.6rem;
}
.ratingButtonTotal {
  margin-top: 4.8rem;
}
.headerRatingBlock {
  width: auto;
  height: 8rem;
  text-align: center;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #50b58d;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 2rem;
  color: #fff;
}
.line {
  grid-column: 1/4;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 0.1rem solid #ddd;
}
.ratingBodyText {
  font-family: 'Roboto Regular', sans-serif;
  text-align: center;
  font-size: 16px;
  line-height: 2.4rem;
  color: #4a4a4a;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
}
.stars {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}
.reviewInput {
  resize: none;
  font-size: 1.6rem;
  padding: 0.75rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.7rem;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
}
.reviewInput:hover {
  border-color: #ffc86e;
}
.reviewInput:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(245,166,35,0.5);
  border-color: #ffc86e;
}
.hint {
  font-family: 'Roboto Regular', sans-serif;
  color: #9b9b9b;
  margin-top: 0rem;
  font-size: 1.2rem;
}
.inputLabel {
  display: flex;
  justify-content: center;
  align-items: center;
}
.starIcon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.starIcon use,
.starIcon path,
.starIcon polygon {
  fill: rgba(216,216,216,0.7);
}
.starIcon use,
.starIcon path,
.starIcon polygon {
  stroke: rgba(216,216,216,0.7);
}
.starIconActive use,
.starIconActive path,
.starIconActive polygon {
  fill: #ff9500;
}
.starIconActive use,
.starIconActive path,
.starIconActive polygon {
  stroke: #ff9500;
}
.titleStars {
  font-weight: 600;
  margin-bottom: 1.2rem;
}
.smileIcon {
  margin-top: 7.2rem;
  margin-bottom: 3.4rem;
}
.smileIcon use,
.smileIcon path,
.smileIcon polygon {
  fill: #ff9500;
}
.smileIcon use,
.smileIcon path,
.smileIcon polygon {
  stroke: #ff9500;
}
.thank {
  margin-bottom: 2rem;
}
.roubleIcon {
  display: inline-flex;
  margin-bottom: -0.5rem;
}
.linkHome {
  text-decoration: none;
  font-size: 2rem;
  text-align: center;
  border-radius: 0rem;
  margin-top: 4.5rem;
}
.loaderWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15rem;
  margin-bottom: 15rem;
}
.modalContent {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  border-radius: 12px;
}
/*# sourceMappingURL=app/Pages/Order/OrderDone/index.css.map */