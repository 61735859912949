.customNotification {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.notificationMessage {
  font-size: 1.4rem;
  flex-grow: 1;
  margin-bottom: 0;
  margin-left: 1.6rem;
  margin-top: 0.2rem;
}
.closeIcon {
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
  opacity: 0.5;
}
.closeIcon path {
  fill: #000;
}
.levelIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.link {
  color: #000;
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
  opacity: 0.8;
}
.link:hover {
  color: #000;
}
.linkIcon {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  margin-bottom: 0.2rem;
}
:global(.notifications-bc) {
  font-family: inherit;
  position: fixed;
  width: 32rem;
  padding: 0 1rem 1rem;
  z-index: 9998;
  box-sizing: border-box;
  height: auto;
  top: auto;
  bottom: 0;
  margin: 0 auto 0 -16rem;
  left: 50%;
}
:global(.notification) {
  position: relative;
  width: 100%;
  color: $light;
  cursor: pointer;
  border-radius: 0.6rem;
  font-size: 0;
  margin: 1rem 0 0;
  padding: 2.4rem 4rem 2.4rem 1.6rem;
  display: block;
  box-sizing: border-box;
  transition: 0.3s ease-in-out;
  transform: translate3d(0, 100%, 0);
  opacity: 0;
  will-change: transform, opacity;
  bottom: 0;
  box-shadow: 0 0.5rem 2rem 0 rgba(0,0,0,0.3);
}
:global(.notification-hidden) {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}
:global(.notification-visible) {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
:global(.notification-error) {
  background-color: $error;
}
:global(.notification-success) {
  background-color: $confirmDark;
}
:global(.notification-warning) {
  background-color: $warning;
  color: $dark;
}
:global(.notification-warning) .link {
  opacity: 0.5;
}
:global(.notification-info) {
  background-color: $control;
}
:global(.notification-dismiss) {
  display: none;
}
:global(.notification-title) {
  display: none;
}
:global(.notification-message) {
  display: none;
}
/*# sourceMappingURL=app/Layout/Notifications.css.map */