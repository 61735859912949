.nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #000;
  padding: 1.2rem 0;
  font-size: 1.4rem;
}
.wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.3rem;
  height: 2.6rem;
  background-color: rgba(0,48,255,0.1);
}
.pagination {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  line-height: 2.6rem;
}
.page,
.pagePlaceholder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0 0.8rem;
}
.page {
  cursor: pointer;
}
.page:hover,
.page:active {
  text-decoration: none;
}
/*    color: $light

    use
      fill: $light

  &:hover
    background-color: $hover

  &:active
    background-color: $active*/
.currentPage {
  pointer-events: none;
}
.currentPage {
  background-color: #fff;
}
.pageLeft,
.pageRight {
  padding: 0;
}
/*# sourceMappingURL=app/Elements/Pagination/index.css.map */