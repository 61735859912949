.wrap
  display flex
  flex-direction column
  align-items stretch
  justify-content flex-start
  height 100%
  background-color white

  .content
    max-width x(989)
    margin 0 auto
    display flex
    flex-direction column
    align-items stretch
    justify-content flex-start
    flex-grow 1

    +media('phone')
      width 86%

    +media('tablet')
      width 80%

.titleRow
  display flex
  flex-direction row
  align-items center
  justify-content flex-start
  margin-bottom x(40)

  .title
    font-size x(16)
    font-weight bold
    margin-left x(24)

  .titleIcon
    svg-fill($accentOrange)

.linksWrap
  display flex
  flex-direction column
  align-items flex-start
  justify-content flex-start

.linkRow
  display inline-flex
  flex-direction row
  align-items flex-start
  justify-content flex-start
  max-width x(580)

  & + &
    margin-top x(21)

  .linkIcon
    svg-fill($actionGreen)
    margin-right x(27)
    flex-shrink 0

  &:hover
    .linkText
      color $actionGreen

  .linkText
    font-size x(16)
    line-height x(25)
    margin-top x(2)
    color $black

.p1
  margin-top x(88)
  margin-bottom x(88)
  margin-left x(120)
  min-height unquote('calc(100vh - ' + x(445) + ')')

  +media('phone')
    margin-top x(75)
    margin-bottom x(75)
    margin-left 0

  +media('tablet')
    margin-top x(75)
    margin-bottom x(75)
    margin-left 0
    min-height unquote('calc(100vh - ' + x(508) + ')')

applyMediaCache()
