.paragraph
  margin-bottom x(66)

.highlighted
  font-style italic
  color $actionGreen
  margin-bottom x(24)
  font-size x(18)
  line-height x(24)

.listItem
  font-size x(16)
  line-height x(22)
  margin-bottom x(22)
  display flex
  flex-direction row
  align-items flex-start
  justify-content flex-start

  &:last-child
    margin-bottom 0

  .itemText
    display inline-block

    +media('phone')
      width 100%

  .listNumber
    color $actionGreen
    font-size x(20)
    line-height x(22)
    width x(40)
    flex-shrink 0

.title
  font-size x(20)
  line-height x(20)
  font-weight bold
  margin-bottom x(40)

.subtitle
  font-size x(16)
  line-height x(22)
  margin-bottom x(22)

applyMediaCache()
