$topMenuHeight = 135
$topMenuHeightMobile = 128

.item + .item
  margin-top x(38)

.logo
  width x(140)
  height x(155)
  margin-top x(80)

.intro
  margin-top x(22)
  text-align center
  font-size 16px
  line-height x(22)
  color $textGray

.link
  text-decoration none
  font-size x(20)
  text-align center
  border-radius x(0)

.howWorks
  font-family $robotoMedium
  font-size x(16)
  padding-bottom x(3)
  border-bottom x(1) dashed

.serviceLabel
  font-family $robotoMedium
  font-size x(17)
  line-height x(24)
  width 100%
  text-align center
  position absolute
  left 0
  right 0
  top 0
  height x(102)
  display flex
  align-items center
  justify-content center
  color $white
  background-color $actionGreen

.buttons
  flexCenter()
  justify-content space-between
  flex-flow row wrap
  max-width x(318)
  height auto
  margin-top x(76)
  margin-bottom x(36)

  .buttonContainer
    flexCenter()
    flex-flow column nowrap
    margin-bottom x(20)
    color $textGray

    .button
      flexCenter()
      width x(80)
      height x(80)
      min-width x(80)
      background-color $accentOrange
      border x(2) solid $accentOrange
      border-radius x(50)

      .buttonImg
        width x(40)
        height x(40)
        min-width x(40)

    .buttonLabel
      font-family $robotoMedium
      font-size x(14)
      line-height x(16)
      margin-top x(3)

.icon
  svg-fill($white)
  width x(40)
  height x(40)
  min-width x(40)

.iam
  font-family $robotoRegular
  font-size x(20)
  color $actionGreen
  padding-bottom x(2)
  border-bottom-width x(2)

.bottom
  margin-bottom x(38)

.paymentRulesBlock
  flexCenter()
  margin-top x(35)
  flex-flow row wrap
  margin-left x(-10)

  .darkLink
    font-family $robotoRegular
    font-size x(16)
    color $textGray
    padding-bottom x(2)
    margin-left x(10)
    border-bottom x(2) solid $placeholderGray
    border-radius x(0)

  .sberLogo
    height x(30)
    margin-left x(15)


.content
  height unquote('calc(100vh - ' + x($topMenuHeight) + ')')
  min-height x(800)
  display flex
  flex-direction row
  align-items center
  justify-content center
  position relative
  background-color $actionGreen

  &.contentDesktop
    height auto
    flex-grow 1

  +media('phone')
    align-items flex-start
    height auto

  +media('tablet')
    height unquote('calc(100vh - ' + x($topMenuHeightMobile) + ')')

.contentBlock
  width x(377)
  height x(619)
  background-color $white
  margin-left -35.5%
  box-shadow 0 4px 4px rgba(0, 0, 0, 0.25)
  display flex
  flex-direction column
  align-items center
  justify-content space-between
  padding-top x(30)
  position relative
  margin-top x(62)

  +media('phone')
    margin-left 0
    margin-top x(73)
    margin-bottom x(50)
    padding-left x(20)
    padding-right x(20)
    width 86%

  +media('tablet')
    position absolute
    left 5%
    margin-left 0
    margin-top 4%

  .blockButtons
    display inline-flex
    flex-direction column
    align-items center
    justify-content flex-start
    z-index 1

  .blockFooter
    display inline-flex
    position absolute
    bottom 0
    left 0
    right 0
    z-index 0

    .footerImage
      width 100%
      max-height x(107)


.willBeAvailableSoon
  display flex
  flex-direction row
  align-items center
  justify-content space-between
  flex-grow 1
  position absolute
  left 0
  right 0
  top x(-63)
  height x(52)
  background-color alpha($white, 0.9)

  .treeIconWrap
    width x(98)
    display flex
    align-items center
    justify-content center

  .treeIcon
    height x(76)
    margin-left x(18)
    margin-right x(20)
    flex-shrink 0

.willBeAvailableSoonText
  font-size x(15)
  line-height x(22)
  font-weight bold
  color $accentOrange
  text-transform uppercase
  flex-grow 1
  height 110%
  display flex
  flex-direction column
  justify-content center
  align-items center
  padding-left x(14)
  padding-right x(14)
  text-align center

.map
  position absolute
  left 0
  right 0
  top 0
  bottom 0
  width 100%
  height 100%


.seoContent
  font-size: 1.6rem;
  line-height: 2.2rem;
  //width x(1280)
  padding-top x(90)
  //padding-bottom x(90)
  //padding-left x(170)
  //padding-right x(170)
  max-width x(989)
  margin 0 auto
  display flex
  flex-direction column

  +media('phone')
    width 86%

  +media('tablet')
    width 80%
  //+media('(max-width: 900px)')
    //padding-left x(70)
    //padding-right x(70)
  .header
    color #63B896
    font-size x(18)
    font-style italic
    font-weight normal
    line-height x(24)
    margin-bottom x(40)

  .title
    //+media('(max-width: 900px)')
    //  text-align center
    font-size: 2rem;
    line-height: 2rem;
    font-weight: bold;
    margin-bottom: 4rem;
  .textWrap
    display flex
    flex-direction row
    justify-content space-between
    +media('(max-width: 900px)')
      flex-direction column

    .text
      width 46%
      margin-bottom x(40)
      +media('(max-width: 900px)')
        width 100%

  .imageWrap
    display flex
    flex-direction row
    justify-content space-between
    flex-wrap: wrap
    +media('(max-width: 900px)')
      flex-direction column
      align-items center
    .block
      width x(271)
      margin-bottom x(40)
      text-align center

      img
        height x(180)
        margin-bottom x(31)

  table
    .number
      vertical-align top
      width 30px
      color #63B896
      font-size 20px;
      line-height 22px;
      padding-bottom 34px

    .description
      vertical-align top

.youtube
  font-weight 500
  border-bottom dashed x(2) #f5a623
  border-radius 0
  font-size x(18)
  line-height x(24)
  margin-bottom x(52)
  cursor pointer
  color #f5a623

.videoModalWrap
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  background-color rgba(0,0,0,0.6)
  z-index 1
  cursor pointer

.videoModal
  top 200px
  left 50%
  transform translateX(-50%)
  position absolute
  width 940px
  height 507px
  padding 20px
  background-color white
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  +media('tablet')
    width 720px
    height 383px
    padding 10px
    iframe
      width 700px
      height 363px

  +media('phone')
    padding 10px
    width 100%
    height 310px
    iframe
      width 100%
      height 290px

.videoModalClose
  position absolute
  top: -16px;
  right: -16px;
  color: white;
  background-color: black;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  cursor pointer;
  justify-content center;
  align-items center;

  svg
    fill white

  +media('phone')
    display none

.videoModalCloseText
  display block
  text-align: center;
  color: white;
  margin-top: 40px;
  text-decoration: underline;
  font-size: 20px;
  text-shadow: 0px 0px 3px #000000;


.paragraphBlock
  margin-bottom x(60)

.howItWorkBtn
  height x(48)
  border-radius x(14)
  margin-bottom x(36)

.howItWorkBtnText
  font-size x(18)
  line-height x(22)

.howItWorkVideoWrap
  width 100%
  margin-bottom x(60)

  iframe
    width 100%
    height x(467)

  +media('phone')
    iframe
      height auto

  +media('tablet')
    iframe
      height x(318)

.marqueeWrap
  position absolute
  left 0
  right 0
  bottom 0
  height x(52)
  font-size x(20)
  line-height x(26)
  font-weight bold
  color $accentOrange
  white-space nowrap
  display flex
  flex-direction row
  align-items center

  & > div > div > span
    display inline-block
    margin 0 x(12) !important


html
  +media('(max-width: 360px)')
    font-size $defaultFontSize * 0.8

applyMediaCache()
