
.wrap
  display flex
  justify-content center
  align-items center
  flex-flow column
  margin-top auto
  margin-bottom @margin-top
  width 100%
  padding-left 10%
  padding-right @padding-left

.message
  font-family $robotoRegular
  font-size x(22)
  line-height: 25px
  margin-bottom x(50)
  max-width x(360)
  text-align center

.button
  width 100%
  height x(60)
  font-family $robotoRegular
  font-size x(18)
  border-radius x(12)

.button + .button
  margin-top x(12)

.transparent
  color $accentOrange

.linkHome
  text-decoration none
  font-size x(20)
  text-align center
  border-radius x(0)
  margin-top x(50)