@keyframes bounce
  0%, 80%, 100%
    transform scale(0.1)

  40%
    transform scale(1)

.wrapper
  display inline-block

.loader
  margin 0
  width x(32)
  text-align center
  display flex
  flex-direction row
  justify-content space-between
  align-items center

  &.big
    width x(32 * 2)

.ball
  width x(8)
  height x(8)
  background-color $accentOrange
  border-radius 100%
  display block
  animation bounce 1.4s ease-in-out 0s infinite both

  &.big
    width x(16)
    height x(16)

.first
  animation-delay -0.32s

.second
  animation-delay -0.16s

.third
  animation-delay 0s