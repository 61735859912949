.customNotification
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  

.notificationMessage
  font-size: x(14)
  flex-grow: 1
  margin-bottom: 0
  margin-left: x(16)
  margin-top: x(2)

.closeIcon
  position: absolute
  right: x(4)
  top: x(4)
  opacity: .5

  path
    fill: $black

.levelIcon
  display: flex
  align-items: center
  justify-content: center
  flex-shrink: 0

  // +svg()
  //   fill: $light

  &.dark
    +svg()
      fill: $black

.link
  color: #000000
  display: flex
  align-items: center
  margin-top: x(8)
  opacity: .8

  &:hover
    color: #000000

.linkIcon
  display: inline-block
  width: x(16)
  height: x(16)
  margin-bottom: x(2)

//   svg
//     width: x(16)
//     height: x(16)

//   +svg()
//     fill: #000000

:global(.notifications-bc)
  font-family: inherit;
  position: fixed;
  width: x(320);
  padding: 0 x(10) x(10);
  z-index: 9998;
  box-sizing: border-box;
  height: auto;
  top: auto;
  bottom: 0;
  margin: 0 auto 0 x(-160);
  left: 50%;

:global(.notification)
  position: relative;
  width: 100%;
  color: $light
  cursor: pointer;
  border-radius: x(6);
  font-size: 0;
  margin: x(10) 0 0
  padding: x(24) x(40) x(24) x(16);
  display: block;
  box-sizing: border-box;
  transition: 0.3s ease-in-out;
  transform: translate3d(0, 100%, 0);
  opacity: 0
  will-change: transform, opacity;
  bottom: 0;
  box-shadow: 0 x(5) x(20) 0 rgba(0,0,0,0.30)

:global(.notification-hidden)
  opacity: 0
  transform: translate3d(0, 100%, 0);

:global(.notification-visible)
  opacity: 1
  transform: translate3d(0, 0, 0);

:global(.notification-error)
  background-color: $error
:global(.notification-success)
  background-color: $confirmDark
:global(.notification-warning)
  background-color: $warning
  color: $dark
  .link
    opacity: .5

:global(.notification-info)
  background-color: $control

:global(.notification-dismiss)
  display: none
:global(.notification-title)
  display: none
:global(.notification-message)
  display: none

// .notifications-{position}
// .notification notification-{level}
// .notification-{state}
// .notification-not-dismissible
// .notification-title
// .notification-message
// .notification-dismiss
// .notification-action-wrapper
// .notification-action-button
