button()
  font-family $robotoRegular
  color $white
  font-size x(20)
  line-height x(24)
  max-width x(293)
  width 100%
  height x(60)
  border-radius x(14)
  margin-top x(54)

.map
  width unquote('calc(100% + ' + x($paddingLeft) * 2 + ')')
  height x(353)
  margin-bottom x(30)
  margin-left - x($paddingLeft)
  margin-right - x($paddingLeft)

  &.disabled
    display none

.subtitleBlock
  display flex
  flex-flow row wrap
  justify-content space-between
  align-items center
  width 100%
  margin-bottom x(20)

.subtitle
  width 100%
  font-family $robotoLight
  font-size x(20)
  line-height x(29)
  color $textGray
  margin-bottom x(20)

.subtitleSmall
  width 100%
  text-align center
  font-family $robotoLight
  font-size x(16)
  color $textGray

.subtitleSub
  width 100%
  text-align center
  font-family $robotoLight
  font-size x(12)
  color $textGray
  margin-bottom x(20)

.mapControlButton
  font-family $robotoMedium
  font-size x(18)
  line-height x(27)
  border-radius x(0)
  border-bottom x(1) dashed $accentOrange

.address
  width auto
  margin-bottom x(0)

.emailWrap
  margin-top x(12)

.credentials
  width 100%
  display flex
  flex-flow row nowrap
  align-items center
  margin-bottom x(12)

  .inputWrap
  .input
    width 100%
    max-width none

.wideInputWrap
.wideInput
  width 100%
  min-width x(0)

.wideInputCity
  margin-bottom x(20)

.accomodation
  display flex
  flex-flow row wrap
  justify-content flex-start
  align-items center
  width 100%

  margin-left x(-19)

  .inputWrap
    margin-left x(20)

.topInput
  margin-top x(14)
  margin-bottom x(12)

.downInput
  margin-top x(14)
  margin-bottom x(69)

.inputWrap
.input
  min-width x(0)
  max-width x(65)
  width auto

.inputAppartamentWrap
  max-width x(77)

.inputWrap
  &.apartmentsWrap,
  .apartmentsInput
    max-width x(35)

  &.floorWrap,
  .floorInput
    max-width x(37)

.inputWrap + .inputWrap
  margin-left x(14)

.textarea
  width 100%
  height x(120)
  max-width x(370)
  max-height x(120)
  min-height x(50)
  min-width x(100)
  border-radius x(18)
  outline none

  &:active
    outline none
  &:hover
    outline none
  &:focus
    outline none

.textareaWrap
  width 100%
  height 100%
  max-width x(370)
  max-height x(150)
  border-radius x(18)

.textareaWrap
  overflow hidden

.textarea
  padding x(10) x(20)

.attachment
  margin-top x(18)

.callTimeSubtitle
  margin-top x(40)

.callTimeIcon
  svg-fill($accentOrange)
  margin-right x(16)

.callTimeNow
  font-size x(18)
  line-height x(24)
  margin-bottom x(40)
  margin-left x(50)
  align-self flex-start
  font-family $robotoMedium
  border-bottom-style dashed
  border-radius x(0)

.selectedTime
  margin-left x(50)
  margin-bottom x(40)
  font-size x(16)
  font-family $robotoMedium
  color $black

.timeSelectorModal
  max-width x(345)
  width 100%
  padding-left x(30)
  padding-right @padding-left
  padding-bottom @padding-left

.timeSelectorModalTitle
  margin-left x(0)
  margin-right @margin-left

.dateSelectorInputs
  display flex
  flex-flow row wrap
  margin-top x(20)

  .dateInput
  .timeInput
    width auto

  .dateInput
    max-width x(130)
    margin-right x(18)

  .timeInput
    max-width x(85)

.dateSelectorButtons
  display flex
  flex-flow row wrap
  align-items center
  margin-top x(16)

  .dateButton
    font-family $robotoRegular
    font-size x(18)
    line-height x(24)
    border-radius x(14)
    height x(42)

  .nowButton
    margin-right x(23)

.priceBlock
  text-align center
  padding-top x(10)
  padding-bottom x(10)
  margin-bottom x(23)
  font-family $robotoMedium
  font-size x(18)
  line-height x(20)
  width 100%
  color $textGray
  border-top x(2) solid $strokeGray
  border-bottom @border-top
  white-space pre-wrap

  .price
    font-size x(24)

  .roubleIcon
    display inline-flex
    margin-bottom x(-6)
    margin-left x(-3)

  .notice
    font-family $robotoLight
    font-size x(12)
    color $hintGray
    
  .noticeOrange
    font-family $robotoMedium
    font-size x(13)
    color $accentOrange

.error
  font-size x(12)
  color $accentOrange
  margin-top x(0)

.errorSms
  color $errorRed
  // position absolute
  margin-top x(10)
  display block

.hint
  text-align center
  color $labelGray
  margin-top x(4)
  margin-bottom x(36)
  display flex
  flex-direction column
  justify-content center

.code
  display flex
  justify-content center
  align-items center
  flex-flow column
  margin-top x(40)

  .codeLabel
    font-family $robotoMedium
    font-size x(16)
    line-height x(24)
    color $textGray

  .codeWrap
  .codeInput
    width x(140)
    text-align center

  .codeInput
    font-family $robotoMedium
    font-size x(29)
    color $textGray
    border-bottom-width x(2)

.privacyCheckbox
  margin-top x(40)

.privacyLink
  border-radius x(0)
  border-bottom x(1) dashed $placeholderGray

.sendCodeButton
.callMasterButton
.preOrderButton
  button()

.sendCodeButton
  margin-top x(30)

.tariffsLinkWrap
  display flex
  justify-content center
  align-items center

.tariffsLink
  font-family $robotoMedium
  font-size x(20)
  line-height x(23)
  border-radius x(0)
  padding-bottom x(3)
  margin-bottom x(60)
  border-bottom-style dashed

.preOrderButton
  margin-top x(25)
  margin-bottom @margin-top

.callMasterButton
  margin-bottom x(0)
  margin-top x(0)
  width 100%
  max-width x(293)
  blockCenter()

.attention
  margin-top x(27)
  margin-bottom x(50)

.wideLoader
  width 100%
  height x(100)
  text-align center

.loaderHeader
  margin x(30)

.loaderBody
  margin x(30)

.divInputData
  max-width x(130)
  margin-right x(18)

.divInputTime
  max-width x(85)

.divResetPhoneContainer
  position relative
  display: flex;
  align-items: baseline;
  justify-content: space-between;

.divResetPhone
  border-bottom: 0.1rem dashed #f5a623
  display: inline
  cursor: pointer
  color: #f5a623
  &:hover
    color: #ffc86e
    border-bottom-color: #ffc86e

.messageRequiredFields
  font-family $robotoRegular
  font-size x(14)
  color $errorRed
  line-height x(19);
  font-weight 300
  margin-top x(16)

.messageRequiredFieldsTop
  margin-left x(20)

.messageRequiredButton
  margin-top x(10)
  display flex
  justify-content center
  align-items center

.testOrderButton
  button()
  margin-top: x(14)

.testOrderText
  font-family $robotoRegular
  font-size: x(20)
  line-height: x(26)
  margin-top x(41)
  margin-left: x(7)
  margin-right x(7)
  margin-bottom x(30)

.testOrderCheckbox
  margin-bottom: x(43)
  margin-top: x(43)
  input
    flex-grow: 0

.sberLogo
  width x(200)
  margin-top x(-20)
  margin-bottom x(60)

.howItWorkBtn
  height x(48)
  border-radius x(14)
  margin-bottom x(36)

.howItWorkBtnText
  font-size x(18)
  line-height x(22)
  white-space nowrap !important

.helpBlock
  display flex
  justify-content center

  .howItWorkBtn:last-child
    margin-left 20px

  +media('phone')
    flex-direction column

  +media('tablet')
    flex-direction column

.howItWorkVideoWrap
  width 100%
  margin-bottom x(60)

  iframe
    width 100%
    height x(467)

  +media('phone')
    iframe
      height auto

  +media('tablet')
    iframe
      height x(318)

.youtube
  font-weight 500
  border-bottom dashed x(2) #f5a623
  border-radius 0
  font-size x(18)
  line-height x(24)
  margin-bottom x(52)
  cursor pointer
  color #f5a623

.modalContent
  //top 5%
  //left 50%
  //transform translateX(-50%)
  //position absolute
  //width 940px
  //height 507px
  padding 20px
  background-color white
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  //+media('tablet')
  //  width 720px
  //  height 383px
  //  padding 10px
  //  iframe
  //    width 700px
  //    height 363px
  //
  //+media('phone')
  //  padding 10px
  //  width 100%
  //  height 310px
  //  iframe
  //    width 100%
  //    height 290px

.orderCalc
  display grid
  gap 2em

  .title
    border-radius x(10)
    background-color $accentOrange
    //background-color #fc8080
    //color $white
    padding: x(10)

    h1, h2, h3
      text-align center
      line-height x(30)

  .inputControl
    width 100%
    border 1px solid #ccc
    background #FFF
    margin 0 0 5px
    padding 10px

  .inputControl:hover
    -webkit-transition border-color 0.3s ease-in-out
    -moz-transition border-color 0.3s ease-in-out
    transition border-color 0.3s ease-in-out
    border 1px solid #aaa

  .inputControl:focus
    outline 0
    border 1px solid #aaa

  .inputRadioSet
    display grid
    gap 1em

    .inputRadio
      align-items center
      background-color #fefdfe
      border-radius 1em
      box-shadow 0 0 1em rgba(0, 0, 0, 0.0625)
      display flex
      padding 1em
      accent-color $accentOrange

      :hover
        cursor pointer

      label
        align-items center
        display flex
        flex 1
        gap 1em

  .inputHelpText
    display flex
    align-items center
    //margin 0

  fieldset
    border 0
    margin 0
    padding 0

    legend
      //font-weight 600
      //margin-block-end 0.5em
      //padding 0
      font-family $robotoLight
      font-size x(14)
      color $textGray
      margin-top x(2)
      margin-bottom x(2)

      .labelRequired
        font-family $robotoRegular
        font-size x(14)
        color $errorRed
        display inline
        line-height x(19);
        font-weight 300
        margin-right x(4)

  table
    border-collapse collapse
    inline-size 100%
    tbody
      color #b4b4b4
      td
        padding-block: 0.125em
    tfoot
      border-top 1px solid #b4b4b4
      font-weight 600

::-webkit-input-placeholder {
  color: #888;
}

:-moz-placeholder {
  color: #888;
}

::-moz-placeholder {
  color: #888;
}

:-ms-input-placeholder {
  color: #888;
}

.tooltip
  background #222
  color white
  pointer-events none
  border-radius 6px
  padding 15px
  font-size 14px
  z-index 9999

  //h1, h3
  //  font-family $robotoRegular
  //  font-size x(23)
  //  line-height x(27)
  //  margin x(0)
  //  margin-bottom x(20)
  //  padding 20px 40px
  //  background-color $accentOrange
  //  border-radius 15px

:global {
  .react-datepicker {
  font-size: 1.3rem !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name, .react-datepicker__day {
  margin: 0.5rem !important;
}}
