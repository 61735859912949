.substrate
  background-color $transparent
  position fixed
  top x(0)
  left x(0)
  width 100%
  height 100%
  z-index 998

.list
  background-color $white
  position absolute
  margin-top x(3) 
  z-index 999
  cursor pointer
  box-shadow 0 0 10px rgba(0,0,0,0.5);
  width x(370)

.item
 padding x(3)
 padding-left x(10)
 padding-right x(10)

.city
 color $paleIconGray
