
.wrap
  display flex
  flex-flow row nowrap
  align-items flex-start
  font-family $robotoRegular
  font-size x(16)
  line-height x(24)
  color $textGray

.icon
  display flex
  justify-content center
  align-items center
  background-color $accentOrange
  font-family $robotoMedium
  color $white
  font-size x(20)
  width x(30)
  height @width
  min-width @width
  margin-right x(12)
  border-radius x(25)

