.transition,
.accordion i:before,
.accordion i:after {
  transition: all 0.25s ease-in-out;
}
.flipIn,
.accordion {
  animation: flipDown 0.5s ease both;
}
.accordion {
  position: relative;
  padding: 0;
  margin: 0;
  padding-bottom: 4px;
  padding-top: 18px;
  border-top: 1px dotted #dce7eb;
}
.accordion:nth-of-type(1) {
  padding-top: 0;
  animation-delay: 0.5s;
}
.accordion:nth-of-type(2) {
  animation-delay: 0.75s;
}
.accordion:nth-of-type(3) {
  animation-delay: 1s;
}
.accordion:last-of-type {
  padding-bottom: 0;
}
.accordion i {
  position: absolute;
  transform: translate(-6px, 0);
  margin-top: 16px;
  right: 0;
}
.accordion i:before,
.accordion i:after {
  content: "";
  position: absolute;
  background-color: #f5a623;
  width: 3px;
  height: 9px;
}
.accordion i:before {
  transform: translate(-2px, 0) rotate(45deg);
}
.accordion i:after {
  transform: translate(2px, 0) rotate(-45deg);
}
.accordion input[type=checkbox] {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}
.accordion input[type=checkbox]:checked~.content {
  margin-top: 0;
  max-height: 0;
  opacity: 0;
  transform: translate(0, 50%);
}
.accordion input[type=checkbox]:checked~i:before {
  transform: translate(2px, 0) rotate(45deg);
}
.accordion input[type=checkbox]:checked~i:after {
  transform: translate(-2px, 0) rotate(-45deg);
}
.accordion .label {
  line-height: 4.2rem;
  font-weight: 300;
  letter-spacing: 1px;
  display: block;
  margin: 0;
  cursor: pointer;
}
.accordionGroup {
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;
}
@-moz-keyframes flipDown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }
  5% {
    opacity: 1;
  }
  80% {
    transform: rotateX(8deg);
  }
  83% {
    transform: rotateX(6deg);
  }
  92% {
    transform: rotateX(-3deg);
  }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}
@-webkit-keyframes flipDown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }
  5% {
    opacity: 1;
  }
  80% {
    transform: rotateX(8deg);
  }
  83% {
    transform: rotateX(6deg);
  }
  92% {
    transform: rotateX(-3deg);
  }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}
@-o-keyframes flipDown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }
  5% {
    opacity: 1;
  }
  80% {
    transform: rotateX(8deg);
  }
  83% {
    transform: rotateX(6deg);
  }
  92% {
    transform: rotateX(-3deg);
  }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}
@keyframes flipDown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }
  5% {
    opacity: 1;
  }
  80% {
    transform: rotateX(8deg);
  }
  83% {
    transform: rotateX(6deg);
  }
  92% {
    transform: rotateX(-3deg);
  }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}
/*# sourceMappingURL=app/Elements/Accordion/index.css.map */