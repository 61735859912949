.overlay
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  background-color transparent
  pointer-events none
  z-index 100
  transition background-color 0.3s ease, opacity 0.3s ease
  opacity 0
  display flex
  flex-direction column
  align-items flex-end
  justify-content flex-start

  &.opened
    opacity 1
    pointer-events auto
    background-color alpha($black, 0.5)

.menuItems
  display inline-flex
  flex-direction column
  align-items flex-end
  justify-content flex-start
  background-color $white

.menuButtonWrap
  border-bottom x(1) solid $accentOrange
  border-radius 0

  &:last-child
    border none

.menuButton
  height x(45)
  width x(211)
  padding-left x(38)
  color $black
  display inline-flex
  flex-direction column
  justify-content center
  align-items flex-start

  &.active
    background-color $accentOrange
    color $white

.closeIcon
  align-self flex-end
  margin-right x(13)
