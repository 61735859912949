.card
  textWrap()

.cardHeader
  textWrap()

.cardContent
  textWrap()

.cardFooter
  textWrap()
