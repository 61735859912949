$imgRadius = 49

button()
  font-family $robotoRegular
  color $white
  font-size x(20)
  line-height x(24)
  max-width x(293)
  width 100%
  height x(60)
  border-radius x(14)
  margin-top x(54)

.greenBlock
  width auto
  height x(80)  
  text-align center
  margin-left - x($paddingLeft)
  margin-right - x($paddingLeft)
  flexCenter()  
  background-color $blockGreen
  font-family $robotoRegular
  font-size x(20)
  color $white

.loaderWrap
  flexCenter()
  width 100%
  height 100%
  margin-top x(150)
  margin-bottom @margin-top

.greenBlockMasters
  font-size x(15)
  line-height x(22);

.iconsStyle
  margin-top x(0)

.masters
  flexCenter()
  flex-flow row wrap
  height x(160)
  width 100%
  margin-bottom x(40)
  margin-top x(30)

.master
  display flex
  flex-flow column
  align-items center
  width x(97)
  height 100%
  border-radius x(9)
  border-top-left-radius x($imgRadius)
  border-top-right-radius @border-top-left-radius
  box-shadow 0 x(3) x(4) $strokeGray
  cursor pointer

.master + .master
  margin-left x(20)

.masterImg
  width x(98)
  height x(98)
  min-width x(98)
  border-radius x($imgRadius)
  object-fit cover
  // border x(1) solid $black

.masterName
  font-family $robotoRegular
  font-size x(14)
  line-height x(22)
  color $labelGray
  margin-top x(4)

.ratingBlock
  display flex
  flex-flow row nowrap
  justify-content center
  align-items center

  .ratingNum
    font-family $robotoMedium
    font-weight 600
    color $labelGray
    font-size x(22)
    line-height x(22)

  .starIcon
    svg-fill($yellow)
    display inline
    width x(25)
    height x(25)
    min-width x(25)
    margin-left x(6)

.time
  font-family $robotoMedium
  font-size x(18)
  line-height x(27)
  text-align center

.masterInfoBlock
.masterImgBig
  blockCenter()

.masterImgBig
  width x(145)
  height x(145)
  min-width x(145)
  border-radius x(73)
  margin-top x(40)
  object-fit cover

.masterInfoBlock
  flexCenter()
  margin-top x(8)

  .nameSelect
    font-family $robotoMedium
    font-size x(16)
    line-height x(22)
    color $labelGray

  .ratingSelect
    font-family $robotoRegular
    font-weight 600
    color $labelGray
    font-size x(22)
    line-height x(22)

  .starIconSelect
    svg-fill($yellow)
    width x(28)
    height x(28)
    min-width x(28)

.ratingSelect
.starIconSelect
  margin-left x(8)

.commentSelect
  font-family $helvetica
  font-size x(16)
  line-height x(20)
  color $textGray
  text-align center
  margin-top x(30)
  margin-bottom @margin-top
  padding-left x(30)
  padding-right @padding-left

.button
  border-radius x(14)
  font-family $robotoRegular
  font-size x(16)
  line-height x(24)
  height x(60)
  max-width x(293)
  width 100%
  margin-top x(40)

.button + .button
  margin-top x(14)

.transparent
  color $accentOrange

.lastElem
  margin-bottom x(90)

.pageWrap
  flexCenter()
  flex-direction: row
  & a
    text-decoration: none


.okIcon 
  margin-top x(46)
  svg-color($actionGreen)
  margin-bottom x(27)

.accentText
  font-size x(20)
  line-height x(24)
  margin-bottom x(18)
  font-family $robotoMedium
  text-align center

.accentTextNormal
  margin-top x(25)
  margin-bottom x(5)
  font-family $robotoLight

.accentTextConfirm
  margin-top x(35)

.buttonCalendar
  margin-top x(67)
  font-size x(20)

.smileSadIcon
  margin-top x(79)
  svg-color($accentOrange)
  margin-bottom x(22)

.accentNotFound
  margin-bottom x(35)
  line-height: 30px;

.linkHome
  font-family: $robotoRegular
  text-decoration none
  font-size 20px 
  margin-top x(50)

.descriptionSelect
  white-space: pre-line

.noTestOrderModal
  max-width x(345)
  width 100%
  padding-left x(26)
  padding-right @padding-left
  padding-bottom @padding-left

.noTestOrderModalButton 
  button()
  margin-top: x(14)

.noTestOrderModalText
  font-family $robotoRegular
  font-size: x(20)
  line-height: x(26)
  margin-top x(41)

  margin-bottom x(30)
  