button()
  font-family $robotoRegular
  color $white
  font-size x(20)
  line-height x(24)
  max-width x(293)
  width 100%
  height x(60)
  border-radius x(14)
  margin-top x(54)

.wideInputWrap
.wideInput
  width 100%
  min-width x(0)

.input
  min-width x(0)
  max-width x(65)
  width auto

.error
  font-size x(12)
  color $accentOrange
  margin-top x(0)

.errorSms
  color $errorRed
  // position absolute
  margin-top x(10)
  display block

.hint
  text-align center
  color $labelGray
  margin-top x(4)
  margin-bottom x(36)
  display flex
  flex-direction column
  align-items center

.code
  display flex
  justify-content center
  align-items center
  flex-flow column
  margin-top x(40)

  .codeLabel
    font-family $robotoMedium
    font-size x(16)
    line-height x(24)
    color $textGray

  .codeWrap
  .codeInput
    width x(140)
    text-align center

  .codeInput
    font-family $robotoMedium
    font-size x(29)
    color $textGray
    border-bottom-width x(2)

.privacyCheckbox
  margin-top x(40)

.privacyLink
  border-radius x(0)
  border-bottom x(1) dashed $placeholderGray

.sendCodeButton
.callMasterButton
  button()

.sendCodeButton
  margin-top x(30)

.callMasterButton
  margin-bottom x(0)
  margin-top x(0)
  width 100%
  max-width x(293)
  blockCenter()

.attention
  margin-top x(27)
  margin-bottom x(50)

.header
  display flex
  flex-flow row nowrap
  align-items flex-start
  font-family $robotoLight
  font-weight 300
  font-size x(20)
  line-height x(29)
  color $textGray
  margin-top x(50)
  margin-bottom x(40)

  &Icon
    display flex
    align-items center
    justify-content center
    background-color $accentOrange
    width x(30)
    height @width
    min-width @width
    margin-right x(12)
    border-radius x(25)

    svg
      svg-fill($white)

.link
  text-decoration none
  font-size x(20)
  text-align center
  border-radius x(0)

.mainPage
  display flex
  justify-content center

  a
    font-family $robotoRegular
    font-size x(20)
    color $actionGreen
    padding-bottom x(2)
    border-bottom-width x(2)
    margin-bottom x(50)
