@font-face {
  font-family: 'Roboto Light';
  font-weight: 400;
  font-style: 'normal';
  font-display: auto;
  src: url("app/styles/fonts/Roboto-Light.ttf") format('truetype');
}
@font-face {
  font-family: 'Roboto Regular';
  font-weight: 500;
  font-style: 'normal';
  font-display: auto;
  src: url("app/styles/fonts/Roboto-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Roboto Medium';
  font-weight: 550;
  font-style: 'normal';
  font-display: auto;
  src: url("app/styles/fonts/Roboto-Medium.ttf") format('truetype');
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
html {
  min-height: 100%;
  background-color: #fff;
  font-family: 'Helvetica Neue', Helvetica Arial, sans-serif;
  font-size: 10px;
  text-size-adjust: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #fff;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
}
html,
body {
  display: flex;
  flex-grow: 1;
  height: auto;
  min-height: 100%;
}
:global(#app) {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-height: 100%;
}
:global(.preload) * {
  transition: none !important;
}
:global(.disable-hover) {
  pointer-events: none;
}
:global(.overflowed) {
  overflow: hidden;
}
@media only screen and (min-width: 1024px) and (max-width: 1680px) {
  html {
    font-size: 9px;
  }
}
@media only screen and (min-width: 1921px) {
  html {
    font-size: 11px;
  }
}
/*# sourceMappingURL=app/styles/app.css.map */