.roubleIcon {
  display: inline-flex;
  width: 2.4rem;
  height: 2.4rem;
}
.timerButton {
  width: 100%;
  height: 6rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.4rem;
  max-width: 30rem;
  border-radius: 1.4rem;
  padding: 2.4rem 1.7rem;
  font-family: 'Roboto Light', sans-serif;
  font-size: 1.9rem;
}
.timeIcon {
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  margin-right: 0.8rem;
}
.timeIcon use,
.timeIcon path,
.timeIcon polygon {
  fill: #f5a623;
}
.totalPrice {
  margin-left: 0.8rem;
  margin-top: 0rem;
  margin-right: -0.3rem;
  font-family: 'Roboto Medium', sans-serif;
  width: 10rem;
  text-align: right;
}
.totalPrice .roubleIcon {
  margin-bottom: -0.5rem;
}
.dropdownIcon {
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
}
.totalDropdownIcon use,
.totalDropdownIcon path,
.totalDropdownIcon polygon {
  fill: #000;
}
.orderTable {
  font-family: 'Roboto Light', sans-serif;
  font-size: 1.2rem;
  max-width: 29rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 3fr 1fr 2fr;
}
.orderTable .right {
  text-align: right;
}
.orderTable .total {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.6rem;
}
.orderTable .total .roubleIcon {
  margin-bottom: -0.5rem;
}
.orderTable .accent .roubleIcon {
  margin-bottom: -0.4rem;
  width: 1.8rem;
  height: 1.8rem;
  min-width: 1.8rem;
}
.accent {
  font-family: 'Roboto Medium', sans-serif;
}
.label {
  font-family: 'Roboto Light', sans-serif;
  font-size: 1rem;
}
.line {
  grid-column: 1/4;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.1rem solid #ddd;
}
.closed {
  display: none;
}
/*# sourceMappingURL=app/Components/OrderTimes/index.css.map */