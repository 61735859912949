
.detailsButton
  margin x(32) auto
  max-width x(297)
  height x(57)
  width 100%
  border-radius x(14)
  font-family $robotoRegular
  font-size x(20)
  line-height x(24)

.detailsContent
  color $accentOrange

.detailsIcon
  svg-fill($accentOrange)
  margin-right x(-10)

.detailsSection
  display flex
  flex-flow column
  margin-bottom x(50)

.infoBlock
  font-family $robotoMedium
  font-size x(16)
  line-height x(24)
  color $textGray
  margin-bottom x(22)
  padding-bottom x(6)
  border-bottom x(2) solid $strokeGray

.description
  font-family $robotoMedium
  font-size x(14)
  line-height x(17)
  margin-bottom x(28)
  white-space: pre-line

.attention
  margin-bottom x(37)

.attachment
  margin-bottom x(30)

.dropdownIcon
  width x(40)
  height x(40)
  min-width x(40)

.callButton
  max-width x(293)
  width 100%
  height 100%
  border-radius x(14)
  blockCenter()
  padding x(13) x(20)
  font-family $robotoRegular
  font-size x(20)
  line-height x(24)

  .callIcon
    svg-color($white)
    margin-right x(13)

  .callButtonText
    flex-grow 1
    text-align left

.callButton + .callButton
  margin-top x(14)

.closed
  display none

