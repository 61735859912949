.greenBlock {
  width: auto;
  height: 8rem;
  text-align: center;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #50b58d;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 2rem;
  color: #fff;
}
.loaderWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 15rem;
  margin-bottom: 15rem;
}
.greenBlockMasters {
  font-size: 1.5rem;
  line-height: 2.2rem;
}
.iconsStyle {
  margin-top: 0rem;
}
.masters {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  height: 16rem;
  width: 100%;
  margin-bottom: 4rem;
  margin-top: 3rem;
}
.master {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 9.7rem;
  height: 100%;
  border-radius: 0.9rem;
  border-top-left-radius: 4.9rem;
  border-top-right-radius: 4.9rem;
  box-shadow: 0 0.3rem 0.4rem #ddd;
  cursor: pointer;
}
.master + .master {
  margin-left: 2rem;
}
.masterImg {
  width: 9.8rem;
  height: 9.8rem;
  min-width: 9.8rem;
  border-radius: 4.9rem;
  object-fit: cover;
}
.masterName {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: #4a4a4a;
  margin-top: 0.4rem;
}
.ratingBlock {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.ratingBlock .ratingNum {
  font-family: 'Roboto Medium', sans-serif;
  font-weight: 600;
  color: #4a4a4a;
  font-size: 2.2rem;
  line-height: 2.2rem;
}
.ratingBlock .starIcon {
  display: inline;
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  margin-left: 0.6rem;
}
.ratingBlock .starIcon use,
.ratingBlock .starIcon path,
.ratingBlock .starIcon polygon {
  fill: #ff9500;
}
.time {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.8rem;
  line-height: 2.7rem;
  text-align: center;
}
.masterInfoBlock,
.masterImgBig {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.masterImgBig {
  width: 14.5rem;
  height: 14.5rem;
  min-width: 14.5rem;
  border-radius: 7.3rem;
  margin-top: 4rem;
  object-fit: cover;
}
.masterInfoBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.8rem;
}
.masterInfoBlock .nameSelect {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #4a4a4a;
}
.masterInfoBlock .ratingSelect {
  font-family: 'Roboto Regular', sans-serif;
  font-weight: 600;
  color: #4a4a4a;
  font-size: 2.2rem;
  line-height: 2.2rem;
}
.masterInfoBlock .starIconSelect {
  width: 2.8rem;
  height: 2.8rem;
  min-width: 2.8rem;
}
.masterInfoBlock .starIconSelect use,
.masterInfoBlock .starIconSelect path,
.masterInfoBlock .starIconSelect polygon {
  fill: #ff9500;
}
.ratingSelect,
.starIconSelect {
  margin-left: 0.8rem;
}
.commentSelect {
  font-family: 'Helvetica Neue', Helvetica Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #191919;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
.button {
  border-radius: 1.4rem;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  height: 6rem;
  max-width: 29.3rem;
  width: 100%;
  margin-top: 4rem;
}
.button + .button {
  margin-top: 1.4rem;
}
.transparent {
  color: #f5a623;
}
.lastElem {
  margin-bottom: 9rem;
}
.pageWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.pageWrap a {
  text-decoration: none;
}
.okIcon {
  margin-top: 4.6rem;
  margin-bottom: 2.7rem;
}
.okIcon use,
.okIcon path,
.okIcon polygon {
  fill: #63b896;
}
.okIcon use,
.okIcon path,
.okIcon polygon {
  stroke: #63b896;
}
.accentText {
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 1.8rem;
  font-family: 'Roboto Medium', sans-serif;
  text-align: center;
}
.accentTextNormal {
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
  font-family: 'Roboto Light', sans-serif;
}
.accentTextConfirm {
  margin-top: 3.5rem;
}
.buttonCalendar {
  margin-top: 6.7rem;
  font-size: 2rem;
}
.smileSadIcon {
  margin-top: 7.9rem;
  margin-bottom: 2.2rem;
}
.smileSadIcon use,
.smileSadIcon path,
.smileSadIcon polygon {
  fill: #f5a623;
}
.smileSadIcon use,
.smileSadIcon path,
.smileSadIcon polygon {
  stroke: #f5a623;
}
.accentNotFound {
  margin-bottom: 3.5rem;
  line-height: 30px;
}
.linkHome {
  font-family: 'Roboto Regular', sans-serif;
  text-decoration: none;
  font-size: 20px;
  margin-top: 5rem;
}
.descriptionSelect {
  white-space: pre-line;
}
.noTestOrderModal {
  max-width: 34.5rem;
  width: 100%;
  padding-left: 2.6rem;
  padding-right: 2.6rem;
  padding-bottom: 2.6rem;
}
.noTestOrderModalButton {
  font-family: 'Roboto Regular', sans-serif;
  color: #fff;
  font-size: 2rem;
  line-height: 2.4rem;
  max-width: 29.3rem;
  width: 100%;
  height: 6rem;
  border-radius: 1.4rem;
  margin-top: 5.4rem;
  margin-top: 1.4rem;
}
.noTestOrderModalText {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 2rem;
  line-height: 2.6rem;
  margin-top: 4.1rem;
  margin-bottom: 3rem;
}
/*# sourceMappingURL=app/Pages/Order/OrderNormal/index.css.map */