.listgroupitem {
  padding: 1rem 0.5rem;
}
.listgroupitem:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.listgroupitem:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
/*# sourceMappingURL=app/Elements/ListGroupItem/index.css.map */