.wrap {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background-color: #fff;
}
.wrap .content {
  max-width: 98.9rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
}
.p1 {
  margin-top: 11.6rem;
}
@media only screen and (max-width: 736px) {
  .wrap .content {
    width: 86%;
  }
  .p1 {
    margin-top: 7.5rem;
  }
}
@media only screen and (min-width: 737px) and (max-width: 1023px) {
  .wrap .content {
    width: 80%;
  }
  .p1 {
    margin-top: 7.5rem;
  }
}
/*# sourceMappingURL=app/Pages/FAQ/index.css.map */