.rateBar
  display flex
  justify-content start
  align-items center
  flex-wrap nowrap

.starIcon
  svg-color($starsGray)

.starIconActive
  svg-color($yellow)

