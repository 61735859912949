.wrap
  background-color $actionGreen
  padding-top x(55)
  padding-bottom x(55)

.content
  max-width x(1100)
  margin 0 auto
  display flex
  flex-direction row
  align-items center
  justify-content space-between
  color white
  font-size x(16)
  line-height x(24)

  +media('phone')
    flex-direction column

  +media('tablet')
    flex-direction column

  .phoneLink
    color white
    font-size x(20)
    font-weight bold

    +media('phone')
      margin x(10) 0

    +media('tablet')
      margin x(10) 0

.socialButtons
  display flex
  flex-direction row
  align-items center
  justify-content flex-start

  +media('phone')
    margin x(10) 0 x(20)

  +media('tablet')
    margin x(10) 0 x(20)

  .socialBtnLink
    margin-right x(10)

    &:last-child
      margin-right 0

  .socialBtn
    width x(24)
    height @width

    svg-fill(#FFF)

applyMediaCache()
