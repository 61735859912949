.wrap {
  background-color: #63b896;
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
}
.content {
  max-width: 110rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.content .phoneLink {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}
.socialButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.socialButtons .socialBtnLink {
  margin-right: 1rem;
}
.socialButtons .socialBtnLink:last-child {
  margin-right: 0;
}
.socialButtons .socialBtn {
  width: 2.4rem;
  height: 2.4rem;
}
.socialButtons .socialBtn use,
.socialButtons .socialBtn path,
.socialButtons .socialBtn polygon {
  fill: #fff;
}
@media only screen and (max-width: 736px) {
  .content {
    flex-direction: column;
  }
  .content .phoneLink {
    margin: 1rem 0;
  }
  .socialButtons {
    margin: 1rem 0 2rem;
  }
}
@media only screen and (min-width: 737px) and (max-width: 1023px) {
  .content {
    flex-direction: column;
  }
  .content .phoneLink {
    margin: 1rem 0;
  }
  .socialButtons {
    margin: 1rem 0 2rem;
  }
}
/*# sourceMappingURL=app/Elements/Footer/index.css.map */