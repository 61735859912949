$paddingLeft = 30

.main
  height auto
  display flex
  flex-direction column

.mainFull
  height auto
  min-height 100%

.wrap
  blockCenter()
  display flex
  flex-flow column nowrap
  justify-content space-between
  align-items center
  max-width maxWidth = x(400)
  width 100%
  flex 1
  min-height 100vh - x(6)

  .footerImg
    max-width maxWidth
    width 100%

.wrapFull
  display flex
  flex-direction column
  flex 1

.content
  display flex
  flex-flow column nowrap
  justify-content center
  align-items center
  width 100%
  min-width x(0)
  padding-left x($paddingLeft)
  padding-right x($paddingLeft)

.topline
  width 100%
  height x(4)
  background-color $actionGreen

.icon
  svg-fill($black)
  width x(30)
  height x(30)
  min-width x(30)

.topMenu
  display flex
  flex-direction row
  align-items center
  justify-content space-between
  max-width x(1280)
  width 100%
  margin 0 auto
  padding x(30) x(40) x(20) x(42)
  height x(128)

  +media('phone')
    padding x(21) 5% x(22) 5%
    height x(121)

  +media('tablet')
    padding x(21) 5% x(22) 5%
    height x(121)

  .leftPart
    display inline-flex
    align-items center
    .logoWrap
      flex-shrink 0
    .logo
      width x(78)
      height x(78)
    .logoText
      color $actionGreen
      max-width x(160)
      font-size x(18)
      margin-left x(26)
      font-style italic
    .logoTextPhone
      text-align center
      width x(170)
      background-color $accentOrange
      color $white
      font-size x(18)
      margin-top x(5)
      font-style italic

  .rightPart
    display inline-flex
    align-items center
    flex-direction row
    justify-content flex-end

    +media('phone')
      display none

    +media('tablet')
      display none

  .menuPart
    display none

    +media('phone')
      margin-left 5%
      display inline-flex

    +media('tablet')
      margin-left 5%
      display inline-flex

.menuButtonWrap
  margin-right x(10)

  &:last-child
    margin-right 0

.menuButton
  height x(38)
  width x(110)
  border x(1.5) solid $accentOrange
  color #444444
  cursor pointer
  font-size x(10)
  text-align center
  border-radius x(23)
  font-weight 500
  display inline-flex
  align-items center
  justify-content center

  &:hover,
  &.active
    background-color $accentOrange
    color $white

.topMenuLine
  border-bottom x(7) solid $labelGray

applyMediaCache()
