.wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: auto;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.iconCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  background-color: #f5a623;
  border-radius: 2rem;
  margin-right: 1.2rem;
}
.iconCircle .icon use,
.iconCircle .icon path,
.iconCircle .icon polygon {
  fill: #fff;
}
.icon {
  height: 1.6rem;
  width: 16;
  min-width: 1.6rem;
}
.icon use,
.icon path,
.icon polygon {
  fill: #000;
}
.labelRequired {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.4rem;
  color: #d0021b;
  display: inline;
  line-height: 1.9rem;
  font-weight: 300;
  margin-left: 0.7rem;
}
/*# sourceMappingURL=app/Components/InfoMessage/index.css.map */