.wrap
  height 100%
  width 100%
  padding-left x($paddingLeft)
  padding-right x($paddingLeft)

.header
  position relative
  display flex
  justify-content center
  width 100%
  padding-top x(27)

.arrowLeftIcon
  position absolute
  svg-fill($black)
  cursor pointer
  height x(50)
  width x(50)
  left x(-16)
  top x(15)

  &:hover
    svg-fill($focusedGreen)

  &:active
    svg-fill($actionGreen)

.orderNum
  font-family $robotoRegular
  font-size x(23)
  line-height x(27)
  color $accentOrange
  margin x(0)

.orderTitle
  font-family $robotoRegular
  font-size x(23)
  line-height x(27)
  color $accentOrange
  margin x(0)
  margin-bottom x(18)

.orderType
  font-family $robotoMedium
  font-size x(14)
  margin-bottom x(12)
  margin-top x(7)
  text-align center

.menuPart
  position absolute
  right 0
  display flex

  // display none
  //
  // +media('phone')
  //   position absolute
  //   right 0
  //   display flex
  //
  // +media('tablet')
  //   position absolute
  //   right 0
  //   display flex

applyMediaCache()
