
.wrap
  display flex
  justify-content center
  align-items center
  flex-flow row nowrap

  .button
    display flex
    justify-content center
    align-items center
    min-width x(50)
    width @min-width
    height @width
    border-radius x(40)
    padding x(0)
    margin x(0)
    font-size x(30)
    box-sizing border-box
    text-decoration none
    transition 0.15s ease-in-out
    border x(1) solid $white
    outline none
    text-transform none
    -webkit-appearance button
    -webkit-font-smoothing inherit
    -moz-osx-font-smoothing inherit
    cursor pointer
    background-color $accentOrange
    color $white

    &:hover
      background-color $focusedOrange

    &:active
      background-color $pressedOrange   

.label
  font-family $robotoMedium
  font-size x(16)
  color $labelGray
  font-weight 502
  margin-right x(10)

.imageWrap
  max-height x(200) 
  display grid
  grid-template-columns 1fr x(30)

.image
  width 100%
  max-height x(200)
  object-fit cover

.crossIcon
  svg-fill($accentOrange)
  width x(30)
  height x(30)
  cursor pointer
  margin-left x(10)

  &:hover
    svg-fill($focusedOrange)

  &:active
    svg-fill($pressedOrange)

.plusInButton
  position relative
  font-family $robotoRegular
  
  
