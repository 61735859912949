.button
  position relative
  display block
  border-radius x(8)
  background-color transparent
  min-width x(120)
  box-sizing border-box
  text-decoration none
  transition 0.15s ease-in-out
  border x(1) solid $white
  outline none
  margin 0
  padding 0
  text-transform none
  -webkit-font-smoothing inherit
  -moz-osx-font-smoothing inherit
  cursor pointer
  padding-left x(27)
  padding-right @padding-left
  blockCenter()

  &.orange
    background-color $accentOrange
    color $white

    &:hover
      background-color $focusedOrange
      color $white

    &:active
      background-color $pressedOrange
      color $white

  &.green
    background-color $actionGreen
    color $white

    &:hover
      background-color $focusedGreen
      color $white

    &:active
      background-color $pressedGreen
      color $white

  &.transparent
    background-color $white
    border x(2) solid $accentOrange
    color $black

  &:disabled
    &, &:hover, &:active
      cursor default
      pointer-events none
      opacity 0.7
      background-color $placeholderGray

.x36
  height x(36)
  min-height x(36)

.x40
  height x(40)
  min-height x(36)

.buttonFocus
  &:focus
    box-shadow 0 0 x(1) x(1) $gray

.content
  opacity 1
  position relative
  width 100%
  height 100%
  display flex
  flex-direction row
  justify-content center
  align-items center
  outline none
  background-color transparent

.loader
  opacity 0
  pointer-events none
  position absolute
  display flex
  flex-direction row
  justify-content center
  align-items center
  top 0
  bottom 0
  left 0
  right 0

.buttonBusy
  cursor progress !important

  .loader
    opacity 1

  .content
    opacity 0

.content, .loader
  transition opacity 0.3s ease-in
