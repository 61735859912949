.wrap
  display flex
  flex-direction column
  align-items stretch
  justify-content flex-start
  height 100%
  background-color white

  .content
    width 100%
    max-width x(989)
    padding-top x(95)
    padding-bottom x(120)
    margin 0 auto
    display flex
    flex-direction row
    align-items stretch
    justify-content flex-start
    flex-grow 1
    min-height unquote('calc(100vh - ' + x(134 + 128 + 7) + ')')

    +media('phone')
      flex-direction column
      padding-top x(75)

    +media('tablet')
      flex-direction column
      padding-top x(75)

    .info
      display inline-flex
      flex-direction column
      align-self flex-start

      +media('phone')
        width 86%
        margin 0 auto

      +media('tablet')
        width 80%
        margin 0 auto

    .button
      width x(198)
      height x(48)
      border-radius x(4)
      align-self flex-start
      margin 0

      +media('phone')
        width 100%

      +media('tablet')
        width 100%

    .buttonText
      font-size x(18)
      line-height x(22)

    .form
      margin-top x(67)

    .inputLabel
      font-size x(16)
      margin-left x(7)

    .inputError
      color $errorRed
      font-size x(14)
      margin-left x(7)

    .inputRow
      margin-bottom x(29)

    .inputWrap
      margin-top x(5)
      display inline-flex

    .input
      border x(1) solid #D8D8D8
      border-radius x(4)
      height x(45)
      padding x(7)
      font-family $robotoRegular
      font-size x(16)
      line-height x(31)

      &.textarea
        height x(102)
        resize none

    .infoText
      font-size x(20)
      line-height x(27)
      color $black
      font-weight 500
      display block
      align-self flex-start

.leftPart,
.rightPart
  display flex
  flex 1
  flex-direction column

.rightPart
  padding-left x(120)

  +media('phone')
    padding-left 0
    width 86%
    margin x(67) auto 0

  +media('tablet')
    padding-left 0
    width 80%
    margin x(67) auto 0

.mapWrap
  width x(497)
  height x(516)
  margin-top x(40)

  +media('phone')
    width 100%

  +media('tablet')
    width 100%

  .map
    width 100%
    height 100%

applyMediaCache()
