.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}
.message {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 2.2rem;
  line-height: 25px;
  margin-bottom: 5rem;
  max-width: 36rem;
  text-align: center;
}
.button {
  width: 100%;
  height: 6rem;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.8rem;
  border-radius: 1.2rem;
}
.button + .button {
  margin-top: 1.2rem;
}
.transparent {
  color: #f5a623;
}
.linkHome {
  text-decoration: none;
  font-size: 2rem;
  text-align: center;
  border-radius: 0rem;
  margin-top: 5rem;
}
/*# sourceMappingURL=app/Components/PaymentFail/index.css.map */