.wrap
  display flex
  flex-direction column
  align-items stretch
  justify-content flex-start
  height 100%
  background-color white

  .content
    max-width x(989)
    margin 0 auto
    display flex
    flex-direction column
    align-items stretch
    justify-content flex-start
    flex-grow 1

    table
      height auto
      width 100%
      border-collapse collapse
      border-spacing 0

      td, th
        border 1px solid #595959
        padding 5px
        //font-weight normal

      th
        background #347c99
        color #fff

      td
        text-align start
        vertical-align text-top

    +media('phone')
      width 86%

    +media('tablet')
      width 80%

.p1
  margin-top x(116)

  +media('phone')
    margin-top x(75)

  +media('tablet')
    margin-top x(75)

.button
  width x(210)
  height x(48)
  border-radius x(4)
  align-self flex-start
  margin 0

  +media('phone')
    width 100%

.video
  margin-bottom x(64)
  height x(500)

  +media('tablet')
    height x(400)

  +media('phone')
    height x(250)

.buttonText
  font-size x(18)
  line-height x(22)

applyMediaCache()
