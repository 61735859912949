x(n = 1)
  $normalized = (n / $defaultFontSize)
  return 1rem * $normalized

svgBlock()
  use, path, polygon
    {block}

svg-color(color)
  svg-fill(color)
  svg-stroke(color)

svg-fill(color)
  +svgBlock()
    fill color

svg-stroke(color)
  +svgBlock()
    stroke color

svgWrapBlock()
  svg
    +svgBlock()
      {block}

svg-wrap-fill(color)
  +svgWrapBlock()
    fill color

svg-wrap-stroke(color)
  +svgWrapBlock()
    stroke color

svg-wrap-color(color)
  svg-wrap-fill(color)
  svg-wrap-stroke(color)

defineFont(font-family, filename, font-weight, font-style)
  @font-face
    font-family font-family
    font-weight font-weight
    font-style font-style
    font-display auto
    src url('app/styles/fonts/' + filename + '.ttf') format('truetype')

placeholder()
  &::-webkit-input-placeholder
    {block}

  &::-moz-placeholder
    {block}

  &:-ms-input-placeholder
    {block}

  &:-moz-placeholder
    {block}

flexCenter()
  display flex
  justify-content center
  align-items center

textWrap()
  white-space normal !important

textBreak()
  word-wrap break-word !important
  word-break break-word !important

blockCenter()
  display block
  margin-left auto
  margin-right auto
