.pageWrap
  flexCenter()
  flex-direction: row

.okIcon
  margin-top x(46)
  svg-color($actionGreen)
  margin-bottom x(27)

.textCloseOrder
  font-family $robotoRegular
  margin-top x(0)
  text-align center
  font-size 20px
  font-weight 500
  line-height x(30)
  color $black
  padding-right x(35)
  padding-left x(35)

.textCloseOrderData
  margin-top x(22)

.ratingButton
  max-width x(293)
  width 100%
  height x(59)
  border-radius x(14)
  blockCenter()
  padding x(13) x(20)
  font-family $robotoRegular
  font-size x(20)
  line-height x(24)
  margin-top x(29)
  margin-bottom x(26)

.ratingButtonTotal
  margin-top x(48)

.headerRatingBlock
  width auto
  height x(80)
  text-align center
  margin-left - x($paddingLeft)
  margin-right - x($paddingLeft)
  flexCenter()
  background-color $blockGreen
  font-family $robotoRegular
  font-size x(20)
  color $white

.line
  grid-column 1 / 4
  margin-top x(15)
  margin-bottom @margin-top
  border-bottom x(1) solid $strokeGray

.ratingBodyText
  font-family $robotoRegular
  text-align center
  font-size 16px
  line-height x(24)
  color $labelGray
  margin-top x(10)
  margin-bottom x(2)

.stars
  display grid
  grid-template-columns 1fr 1fr 1fr 1fr 1fr
  justify-content center
  align-items center
  margin-bottom x(20)

.reviewInput
  resize none
  font-size x(16)
  padding x(7.5) x(7.5)
  border 1px solid #ced4da
  border-radius x(7)
  color #495057
  transition background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s
  appearance none

  &:hover
    border-color $focusedOrange

  &:focus
    outline 0 none
    outline-offset 0
    box-shadow 0 0 0 x(2) $paleAccentOrange
    border-color $focusedOrange

.hint
  font-family $robotoRegular
  color $hintGray
  margin-top x(0)
  font-size x(12)

.inputLabel
  display flex
  justify-content center
  align-items center

.starIcon
  svg-color($starsGray)
  blockCenter()

.starIconActive
  svg-color($yellow)

.titleStars
  font-weight 600
  margin-bottom x(12)

.smileIcon
  svg-color($yellow)
  margin-top x(72)
  margin-bottom x(34)

.thank
  margin-bottom x(20)

.roubleIcon
  display inline-flex
  margin-bottom x(-5)

.linkHome
  text-decoration none
  font-size x(20)
  text-align center
  border-radius x(0)
  margin-top x(45)

.loaderWrap
  flexCenter()
  width 100%
  margin-top x(150)
  margin-bottom @margin-top

.modalContent
  padding 20px
  background-color white
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
