.button
  max-width x(293)
  width 100%
  height x(60)
  border-radius x(14)
  blockCenter()
  padding x(13) x(20)
  font-family $robotoRegular
  font-size x(18)
  line-height x(24)
  margin-top x(12)

  .callIcon
    svg-color($white)
    margin-right x(5)

.button + .button
  margin-top x(14)

.modal
  max-width x(345)
  width 100%
  padding-left x(30)
  padding-right @padding-left
  padding-bottom @padding-left

.title
  margin-left x(0)
  margin-right @margin-left
  width 100%
  font-family $robotoLight
  font-size x(20)
  line-height x(29)
  color $textGray
  margin-bottom x(10)

.text
  width 100%
  font-family $robotoLight
  font-size x(20)
  line-height x(29)
  color $textGray
  margin-bottom x(10)

.wrap
  display flex
  justify-content center
  align-items center
  flex-flow column
  margin-top auto
  margin-bottom @margin-top
  width 100%
  padding-left 10%
  padding-right @padding-left

  .linkHome
    text-decoration none
    font-size x(20)
    text-align center
    border-radius x(0)
    margin-top x(35)
    margin-bottom x(20)
