.button {
  max-width: 29.3rem;
  width: 100%;
  height: 6rem;
  border-radius: 1.4rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 1.3rem 2rem;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-top: 1.2rem;
}
.button .callIcon {
  margin-right: 0.5rem;
}
.button .callIcon use,
.button .callIcon path,
.button .callIcon polygon {
  fill: #fff;
}
.button .callIcon use,
.button .callIcon path,
.button .callIcon polygon {
  stroke: #fff;
}
.button + .button {
  margin-top: 1.4rem;
}
.modal {
  max-width: 34.5rem;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
}
.title {
  margin-left: 0rem;
  margin-right: 0rem;
  width: 100%;
  font-family: 'Roboto Light', sans-serif;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #191919;
  margin-bottom: 1rem;
}
.text {
  width: 100%;
  font-family: 'Roboto Light', sans-serif;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #191919;
  margin-bottom: 1rem;
}
.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}
.wrap .linkHome {
  text-decoration: none;
  font-size: 2rem;
  text-align: center;
  border-radius: 0rem;
  margin-top: 3.5rem;
  margin-bottom: 2rem;
}
/*# sourceMappingURL=app/Modals/ModalMasterNotFound/index.css.map */