
.labelWrap
  position relative
  display flex
  align-items center
  cursor pointer
  height 30px

.label
  &.withCustom
    margin-left 10px

.customWrap
  position absolute
  display flex
  justify-content center
  align-items center
  width 23px
  height 23px
  margin-right 10px
  background-color $white
  border 1px solid #979797
  border-radius 15px

.customDot
  &.checked
    width 11px
    height 11px
    background-color $accentOrange
    border-radius 15px
