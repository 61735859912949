.wrap
  display flex
  flex-direction column
  align-items stretch
  justify-content flex-start
  height 100%
  background-color white

  .content
    width 100%
    max-width x(500)
    padding-top x(95)
    padding-bottom x(120)
    margin 0 auto
    display flex
    flex-direction column
    align-items stretch
    justify-content start
    flex-grow 1
    min-height unquote('calc(100vh - ' + x(134 + 128 + 7) + ')')

    +media('phone')
      flex-direction column
      padding-top x(75)

    +media('tablet')
      flex-direction column
      padding-top x(75)

    .button
      width x(198)
      height x(48)
      border-radius x(4)
      align-self flex-start
      margin 0

      +media('phone')
        width 100%

      +media('tablet')
        width 100%

    .buttonText
      font-size x(18)
      line-height x(22)

    .form
      margin-top x(20)
      padding x(10)

    .inputLabel
      font-size x(16)
      margin-left x(7)

    .inputError
      color $errorRed
      font-size x(14)
      margin-left x(7)

    .inputRow
      margin-bottom x(29)

    .inputWrap
      margin-top x(5)

    .input
      border x(1) solid #D8D8D8
      border-radius x(4)
      height x(45)
      padding x(7)
      font-family $robotoRegular
      font-size x(16)
      line-height x(31)

      &.textarea
        height x(102)
        resize none

    .infoText
      font-size x(20)
      line-height x(27)
      color $black
      font-weight 500
      display block
      align-self flex-start
      padding x(10)

applyMediaCache()
