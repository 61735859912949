.map {
  width: calc(100% + 3rem);
  height: 35.3rem;
  margin-bottom: 3rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.map.disabled {
  display: none;
}
.subtitleBlock {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}
.subtitle {
  width: 100%;
  font-family: 'Roboto Light', sans-serif;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #191919;
  margin-bottom: 2rem;
}
.subtitleSmall {
  width: 100%;
  text-align: center;
  font-family: 'Roboto Light', sans-serif;
  font-size: 1.6rem;
  color: #191919;
}
.subtitleSub {
  width: 100%;
  text-align: center;
  font-family: 'Roboto Light', sans-serif;
  font-size: 1.2rem;
  color: #191919;
  margin-bottom: 2rem;
}
.mapControlButton {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.8rem;
  line-height: 2.7rem;
  border-radius: 0rem;
  border-bottom: 0.1rem dashed #f5a623;
}
.address {
  width: auto;
  margin-bottom: 0rem;
}
.emailWrap {
  margin-top: 1.2rem;
}
.credentials {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 1.2rem;
}
.credentials .inputWrap,
.credentials .input {
  width: 100%;
  max-width: none;
}
.wideInputWrap,
.wideInput {
  width: 100%;
  min-width: 0rem;
}
.wideInputCity {
  margin-bottom: 2rem;
}
.accomodation {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: -1.9rem;
}
.accomodation .inputWrap {
  margin-left: 2rem;
}
.topInput {
  margin-top: 1.4rem;
  margin-bottom: 1.2rem;
}
.downInput {
  margin-top: 1.4rem;
  margin-bottom: 6.9rem;
}
.inputWrap,
.input {
  min-width: 0rem;
  max-width: 6.5rem;
  width: auto;
}
.inputAppartamentWrap {
  max-width: 7.7rem;
}
.inputWrap.apartmentsWrap,
.inputWrap .apartmentsInput {
  max-width: 3.5rem;
}
.inputWrap.floorWrap,
.inputWrap .floorInput {
  max-width: 3.7rem;
}
.inputWrap + .inputWrap {
  margin-left: 1.4rem;
}
.textarea {
  width: 100%;
  height: 12rem;
  max-width: 37rem;
  max-height: 12rem;
  min-height: 5rem;
  min-width: 10rem;
  border-radius: 1.8rem;
  outline: none;
}
.textarea:active {
  outline: none;
}
.textarea:hover {
  outline: none;
}
.textarea:focus {
  outline: none;
}
.textareaWrap {
  width: 100%;
  height: 100%;
  max-width: 37rem;
  max-height: 15rem;
  border-radius: 1.8rem;
}
.textareaWrap {
  overflow: hidden;
}
.textarea {
  padding: 1rem 2rem;
}
.attachment {
  margin-top: 1.8rem;
}
.callTimeSubtitle {
  margin-top: 4rem;
}
.callTimeIcon {
  margin-right: 1.6rem;
}
.callTimeIcon use,
.callTimeIcon path,
.callTimeIcon polygon {
  fill: #f5a623;
}
.callTimeNow {
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-bottom: 4rem;
  margin-left: 5rem;
  align-self: flex-start;
  font-family: 'Roboto Medium', sans-serif;
  border-bottom-style: dashed;
  border-radius: 0rem;
}
.selectedTime {
  margin-left: 5rem;
  margin-bottom: 4rem;
  font-size: 1.6rem;
  font-family: 'Roboto Medium', sans-serif;
  color: #000;
}
.timeSelectorModal {
  max-width: 34.5rem;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
}
.timeSelectorModalTitle {
  margin-left: 0rem;
  margin-right: 0rem;
}
.dateSelectorInputs {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2rem;
}
.dateSelectorInputs .dateInput,
.dateSelectorInputs .timeInput {
  width: auto;
}
.dateSelectorInputs .dateInput {
  max-width: 13rem;
  margin-right: 1.8rem;
}
.dateSelectorInputs .timeInput {
  max-width: 8.5rem;
}
.dateSelectorButtons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 1.6rem;
}
.dateSelectorButtons .dateButton {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.8rem;
  line-height: 2.4rem;
  border-radius: 1.4rem;
  height: 4.2rem;
}
.dateSelectorButtons .nowButton {
  margin-right: 2.3rem;
}
.priceBlock {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 2.3rem;
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.8rem;
  line-height: 2rem;
  width: 100%;
  color: #191919;
  border-top: 0.2rem solid #ddd;
  border-bottom: 0.2rem solid #ddd;
  white-space: pre-wrap;
}
.priceBlock .price {
  font-size: 2.4rem;
}
.priceBlock .roubleIcon {
  display: inline-flex;
  margin-bottom: -0.6rem;
  margin-left: -0.3rem;
}
.priceBlock .notice {
  font-family: 'Roboto Light', sans-serif;
  font-size: 1.2rem;
  color: #9b9b9b;
}
.priceBlock .noticeOrange {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.3rem;
  color: #f5a623;
}
.error {
  font-size: 1.2rem;
  color: #f5a623;
  margin-top: 0rem;
}
.errorSms {
  color: #d0021b;
  margin-top: 1rem;
  display: block;
}
.hint {
  text-align: center;
  color: #4a4a4a;
  margin-top: 0.4rem;
  margin-bottom: 3.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.code {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin-top: 4rem;
}
.code .codeLabel {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #191919;
}
.code .codeWrap,
.code .codeInput {
  width: 14rem;
  text-align: center;
}
.code .codeInput {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 2.9rem;
  color: #191919;
  border-bottom-width: 0.2rem;
}
.privacyCheckbox {
  margin-top: 4rem;
}
.privacyLink {
  border-radius: 0rem;
  border-bottom: 0.1rem dashed #979797;
}
.sendCodeButton,
.callMasterButton,
.preOrderButton {
  font-family: 'Roboto Regular', sans-serif;
  color: #fff;
  font-size: 2rem;
  line-height: 2.4rem;
  max-width: 29.3rem;
  width: 100%;
  height: 6rem;
  border-radius: 1.4rem;
  margin-top: 5.4rem;
}
.sendCodeButton {
  margin-top: 3rem;
}
.tariffsLinkWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tariffsLink {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 2rem;
  line-height: 2.3rem;
  border-radius: 0rem;
  padding-bottom: 0.3rem;
  margin-bottom: 6rem;
  border-bottom-style: dashed;
}
.preOrderButton {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.callMasterButton {
  margin-bottom: 0rem;
  margin-top: 0rem;
  width: 100%;
  max-width: 29.3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.attention {
  margin-top: 2.7rem;
  margin-bottom: 5rem;
}
.wideLoader {
  width: 100%;
  height: 10rem;
  text-align: center;
}
.loaderHeader {
  margin: 3rem;
}
.loaderBody {
  margin: 3rem;
}
.divInputData {
  max-width: 13rem;
  margin-right: 1.8rem;
}
.divInputTime {
  max-width: 8.5rem;
}
.divResetPhoneContainer {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.divResetPhone {
  border-bottom: 0.1rem dashed #f5a623;
  display: inline;
  cursor: pointer;
  color: #f5a623;
}
.divResetPhone:hover {
  color: #ffc86e;
  border-bottom-color: #ffc86e;
}
.messageRequiredFields {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.4rem;
  color: #d0021b;
  line-height: 1.9rem;
  font-weight: 300;
  margin-top: 1.6rem;
}
.messageRequiredFieldsTop {
  margin-left: 2rem;
}
.messageRequiredButton {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testOrderButton {
  font-family: 'Roboto Regular', sans-serif;
  color: #fff;
  font-size: 2rem;
  line-height: 2.4rem;
  max-width: 29.3rem;
  width: 100%;
  height: 6rem;
  border-radius: 1.4rem;
  margin-top: 5.4rem;
  margin-top: 1.4rem;
}
.testOrderText {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 2rem;
  line-height: 2.6rem;
  margin-top: 4.1rem;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  margin-bottom: 3rem;
}
.testOrderCheckbox {
  margin-bottom: 4.3rem;
  margin-top: 4.3rem;
}
.testOrderCheckbox input {
  flex-grow: 0;
}
.sberLogo {
  width: 20rem;
  margin-top: -2rem;
  margin-bottom: 6rem;
}
.howItWorkBtn {
  height: 4.8rem;
  border-radius: 1.4rem;
  margin-bottom: 3.6rem;
}
.howItWorkBtnText {
  font-size: 1.8rem;
  line-height: 2.2rem;
  white-space: nowrap !important;
}
.helpBlock {
  display: flex;
  justify-content: center;
}
.helpBlock .howItWorkBtn:last-child {
  margin-left: 20px;
}
.howItWorkVideoWrap {
  width: 100%;
  margin-bottom: 6rem;
}
.howItWorkVideoWrap iframe {
  width: 100%;
  height: 46.7rem;
}
.youtube {
  font-weight: 500;
  border-bottom: dashed 0.2rem #f5a623;
  border-radius: 0;
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-bottom: 5.2rem;
  cursor: pointer;
  color: #f5a623;
}
.modalContent {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  border-radius: 12px;
}
.orderCalc {
  display: grid;
  gap: 2em;
}
.orderCalc .title {
  border-radius: 1rem;
  background-color: #f5a623;
  padding: 1rem;
}
.orderCalc .title h1,
.orderCalc .title h2,
.orderCalc .title h3 {
  text-align: center;
  line-height: 3rem;
}
.orderCalc .inputControl {
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px;
}
.orderCalc .inputControl:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}
.orderCalc .inputControl:focus {
  outline: 0;
  border: 1px solid #aaa;
}
.orderCalc .inputRadioSet {
  display: grid;
  gap: 1em;
}
.orderCalc .inputRadioSet .inputRadio {
  align-items: center;
  background-color: #fefdfe;
  border-radius: 1em;
  box-shadow: 0 0 1em rgba(0,0,0,0.063);
  display: flex;
  padding: 1em;
  accent-color: #f5a623;
}
.orderCalc .inputRadioSet .inputRadio :hover {
  cursor: pointer;
}
.orderCalc .inputRadioSet .inputRadio label {
  align-items: center;
  display: flex;
  flex: 1;
  gap: 1em;
}
.orderCalc .inputHelpText {
  display: flex;
  align-items: center;
}
.orderCalc fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.orderCalc fieldset legend {
  font-family: 'Roboto Light', sans-serif;
  font-size: 1.4rem;
  color: #191919;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
.orderCalc fieldset legend .labelRequired {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.4rem;
  color: #d0021b;
  display: inline;
  line-height: 1.9rem;
  font-weight: 300;
  margin-right: 0.4rem;
}
.orderCalc table {
  border-collapse: collapse;
  inline-size: 100%;
}
.orderCalc table tbody {
  color: #b4b4b4;
}
.orderCalc table tbody td {
  padding-block: 0.125em;
}
.orderCalc table tfoot {
  border-top: 1px solid #b4b4b4;
  font-weight: 600;
}
::-webkit-input-placeholder {
  color: #888;
}
:-moz-placeholder {
  color: #888;
}
::-moz-placeholder {
  color: #888;
}
:-ms-input-placeholder {
  color: #888;
}
.tooltip {
  background: #222;
  color: #fff;
  pointer-events: none;
  border-radius: 6px;
  padding: 15px;
  font-size: 14px;
  z-index: 9999;
}
:global .react-datepicker {
  font-size: 1.3rem !important;
}
:global .react-datepicker__current-month {
  font-size: 1.5rem !important;
}
:global .react-datepicker__header {
  padding-top: 6px !important;
}
:global .react-datepicker__navigation {
  top: 13px !important;
}
:global .react-datepicker__day-name,
:global .react-datepicker__day {
  margin: 0.5rem !important;
}
/*# sourceMappingURL=app/Pages/OrderNew/index.css.map */