.wrap {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background-color: #fff;
}
.wrap .content {
  max-width: 98.9rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
}
.imageWrap {
  height: 30vw;
  min-height: 300px;
  background-image: url("/assets/Еzhek_landing.jpg");
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
}
.p1 {
  margin-top: 7.5rem;
}
@media only screen and (max-width: 736px) {
  .wrap .content {
    width: 86%;
  }
}
@media only screen and (min-width: 737px) and (max-width: 1023px) {
  .wrap .content {
    width: 80%;
  }
}
/*# sourceMappingURL=app/Pages/About/index.css.map */