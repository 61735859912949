.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
}
.wrap .button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 5rem;
  width: 5rem;
  height: 5rem;
  border-radius: 4rem;
  padding: 0rem;
  margin: 0rem;
  font-size: 3rem;
  box-sizing: border-box;
  text-decoration: none;
  transition: 0.15s ease-in-out;
  border: 0.1rem solid #fff;
  outline: none;
  text-transform: none;
  -webkit-appearance: button;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  cursor: pointer;
  background-color: #f5a623;
  color: #fff;
}
.wrap .button:hover {
  background-color: #ffc86e;
}
.wrap .button:active {
  background-color: #ff9200;
}
.label {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.6rem;
  color: #4a4a4a;
  font-weight: 502;
  margin-right: 1rem;
}
.imageWrap {
  max-height: 20rem;
  display: grid;
  grid-template-columns: 1fr 3rem;
}
.image {
  width: 100%;
  max-height: 20rem;
  object-fit: cover;
}
.crossIcon {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  margin-left: 1rem;
}
.crossIcon use,
.crossIcon path,
.crossIcon polygon {
  fill: #f5a623;
}
.crossIcon:hover use,
.crossIcon:hover path,
.crossIcon:hover polygon {
  fill: #ffc86e;
}
.crossIcon:active use,
.crossIcon:active path,
.crossIcon:active polygon {
  fill: #ff9200;
}
.plusInButton {
  position: relative;
  font-family: 'Roboto Regular', sans-serif;
}
/*# sourceMappingURL=app/Components/Attachment/index.css.map */