.item + .item {
  margin-top: 3.8rem;
}
.logo {
  width: 14rem;
  height: 15.5rem;
  margin-top: 8rem;
}
.intro {
  margin-top: 2.2rem;
  text-align: center;
  font-size: 16px;
  line-height: 2.2rem;
  color: #191919;
}
.link {
  text-decoration: none;
  font-size: 2rem;
  text-align: center;
  border-radius: 0rem;
}
.howWorks {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.6rem;
  padding-bottom: 0.3rem;
  border-bottom: 0.1rem dashed;
}
.serviceLabel {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.7rem;
  line-height: 2.4rem;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 10.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #63b896;
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  max-width: 31.8rem;
  height: auto;
  margin-top: 7.6rem;
  margin-bottom: 3.6rem;
}
.buttons .buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  margin-bottom: 2rem;
  color: #191919;
}
.buttons .buttonContainer .button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  min-width: 8rem;
  background-color: #f5a623;
  border: 0.2rem solid #f5a623;
  border-radius: 5rem;
}
.buttons .buttonContainer .button .buttonImg {
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
}
.buttons .buttonContainer .buttonLabel {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-top: 0.3rem;
}
.icon {
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
}
.icon use,
.icon path,
.icon polygon {
  fill: #fff;
}
.iam {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 2rem;
  color: #63b896;
  padding-bottom: 0.2rem;
  border-bottom-width: 0.2rem;
}
.bottom {
  margin-bottom: 3.8rem;
}
.paymentRulesBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.5rem;
  flex-flow: row wrap;
  margin-left: -1rem;
}
.paymentRulesBlock .darkLink {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.6rem;
  color: #191919;
  padding-bottom: 0.2rem;
  margin-left: 1rem;
  border-bottom: 0.2rem solid #979797;
  border-radius: 0rem;
}
.paymentRulesBlock .sberLogo {
  height: 3rem;
  margin-left: 1.5rem;
}
.content {
  height: calc(100vh - 13.5rem);
  min-height: 80rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #63b896;
}
.content.contentDesktop {
  height: auto;
  flex-grow: 1;
}
.contentBlock {
  width: 37.7rem;
  height: 61.9rem;
  background-color: #fff;
  margin-left: -35.5%;
  box-shadow: 0 4px 4px rgba(0,0,0,0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;
  position: relative;
  margin-top: 6.2rem;
}
.contentBlock .blockButtons {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
}
.contentBlock .blockFooter {
  display: inline-flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}
.contentBlock .blockFooter .footerImage {
  width: 100%;
  max-height: 10.7rem;
}
.willBeAvailableSoon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: -6.3rem;
  height: 5.2rem;
  background-color: rgba(255,255,255,0.9);
}
.willBeAvailableSoon .treeIconWrap {
  width: 9.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.willBeAvailableSoon .treeIcon {
  height: 7.6rem;
  margin-left: 1.8rem;
  margin-right: 2rem;
  flex-shrink: 0;
}
.willBeAvailableSoonText {
  font-size: 1.5rem;
  line-height: 2.2rem;
  font-weight: bold;
  color: #f5a623;
  text-transform: uppercase;
  flex-grow: 1;
  height: 110%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  text-align: center;
}
.map {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.seoContent {
  font-size: 1.6rem;
  line-height: 2.2rem;
  padding-top: 9rem;
  max-width: 98.9rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.header {
  color: #63b896;
  font-size: 1.8rem;
  font-style: italic;
  font-weight: normal;
  line-height: 2.4rem;
  margin-bottom: 4rem;
}
.title {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  margin-bottom: 4rem;
}
.textWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.textWrap .text {
  width: 46%;
  margin-bottom: 4rem;
}
.imageWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.imageWrap .block {
  width: 27.1rem;
  margin-bottom: 4rem;
  text-align: center;
}
.imageWrap .block img {
  height: 18rem;
  margin-bottom: 3.1rem;
}
table .number {
  vertical-align: top;
  width: 30px;
  color: #63b896;
  font-size: 20px;
  line-height: 22px;
  padding-bottom: 34px;
}
table .description {
  vertical-align: top;
}
.youtube {
  font-weight: 500;
  border-bottom: dashed 0.2rem #f5a623;
  border-radius: 0;
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-bottom: 5.2rem;
  cursor: pointer;
  color: #f5a623;
}
.videoModalWrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  z-index: 1;
  cursor: pointer;
}
.videoModal {
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 940px;
  height: 507px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  border-radius: 12px;
}
.videoModalClose {
  position: absolute;
  top: -16px;
  right: -16px;
  color: #fff;
  background-color: #000;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.videoModalClose svg {
  fill: #fff;
}
.videoModalCloseText {
  display: block;
  text-align: center;
  color: #fff;
  margin-top: 40px;
  text-decoration: underline;
  font-size: 20px;
  text-shadow: 0px 0px 3px #000;
}
.paragraphBlock {
  margin-bottom: 6rem;
}
.howItWorkBtn {
  height: 4.8rem;
  border-radius: 1.4rem;
  margin-bottom: 3.6rem;
}
.howItWorkBtnText {
  font-size: 1.8rem;
  line-height: 2.2rem;
}
.howItWorkVideoWrap {
  width: 100%;
  margin-bottom: 6rem;
}
.howItWorkVideoWrap iframe {
  width: 100%;
  height: 46.7rem;
}
.marqueeWrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 5.2rem;
  font-size: 2rem;
  line-height: 2.6rem;
  font-weight: bold;
  color: #f5a623;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.marqueeWrap > div > div > span {
  display: inline-block;
  margin: 0 1.2rem !important;
}
@media only screen and (max-width: 736px) {
  .content {
    align-items: flex-start;
    height: auto;
  }
  .contentBlock {
    margin-left: 0;
    margin-top: 7.3rem;
    margin-bottom: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 86%;
  }
  .seoContent {
    width: 86%;
  }
  .videoModal {
    padding: 10px;
    width: 100%;
    height: 310px;
  }
  .videoModal iframe {
    width: 100%;
    height: 290px;
  }
  .videoModalClose {
    display: none;
  }
  .howItWorkVideoWrap iframe {
    height: auto;
  }
}
@media only screen and (min-width: 737px) and (max-width: 1023px) {
  .content {
    height: calc(100vh - 12.8rem);
  }
  .contentBlock {
    position: absolute;
    left: 5%;
    margin-left: 0;
    margin-top: 4%;
  }
  .seoContent {
    width: 80%;
  }
  .videoModal {
    width: 720px;
    height: 383px;
    padding: 10px;
  }
  .videoModal iframe {
    width: 700px;
    height: 363px;
  }
  .howItWorkVideoWrap iframe {
    height: 31.8rem;
  }
}
@media (max-width: 900px) {
  .textWrap {
    flex-direction: column;
  }
  .textWrap .text {
    width: 100%;
  }
  .imageWrap {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 360px) {
  html {
    font-size: 8px;
  }
}
/*# sourceMappingURL=app/Pages/Start/index.css.map */