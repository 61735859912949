.link
  cursor pointer
  border-radius x(8)
  display inline-block
  outline none
  text-decoration none
  background transparent

  &.orange
    color $accentOrange

    &.underlined
      border-bottom-color @color

    &:hover
      color $focusedOrange

      &.underlined
        border-bottom-color @color

    &:active
      color $pressedOrange

      &.underlined
        border-bottom-color @color

  &.green
    color $actionGreen

    &:hover
      color $focusedGreen

      &.underlined
        border-bottom-color @color

    &:active
      color $pressedGreen

      &.underlined
        border-bottom-color @color

  &.dark
    color $textGray

    &:hover
      color $focusedGreen

      &.underlined
        border-bottom-color @color

    &:active
      color $pressedGreen

      &.underlined
        border-bottom-color @color

  &.white
    color $white

    &:hover
      color $white

      &.underlined
        border-bottom-color @color

    &:active
      color $white

      &.underlined
        border-bottom-color @color

.underlined
  border-radius x(0)
  border-bottom x(2) solid

.anchor
  position relative
  display flex
  flex-direction row
  align-items center

  & .number
    cursor default
    display flex
    align-items center
    justify-content center
    width x(25)
    height @width
    border-radius (@width / 2)
    margin-right x(12)

