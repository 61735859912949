.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  pointer-events: none;
  z-index: 100;
  transition: background-color 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.overlay.opened {
  opacity: 1;
  pointer-events: auto;
  background-color: rgba(0,0,0,0.5);
}
.menuItems {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: #fff;
}
.menuButtonWrap {
  border-bottom: 0.1rem solid #f5a623;
  border-radius: 0;
}
.menuButtonWrap:last-child {
  border: none;
}
.menuButton {
  height: 4.5rem;
  width: 21.1rem;
  padding-left: 3.8rem;
  color: #000;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.menuButton.active {
  background-color: #f5a623;
  color: #fff;
}
.closeIcon {
  align-self: flex-end;
  margin-right: 1.3rem;
}
/*# sourceMappingURL=app/Elements/MobileMenu/index.css.map */