$paddingLeft = 15

.waitingForPay
  text-align center
  margin-bottom 20px
  font-size x(20)

button()
  font-family $robotoRegular
  color $white
  font-size x(20)
  line-height x(24)
  max-width x(293)
  width 100%
  height x(60)
  border-radius x(14)
  margin-top x(12)

.map
  max-height x(357)
  max-width x(400)
  width auto
  margin-left - x($paddingLeft)
  margin-right - x($paddingLeft)

.searchMetaBlock
  width auto
  text-align center
  margin-left - x($paddingLeft)
  margin-right - x($paddingLeft)
  padding-top x(14)
  padding-bottom x(14)
  background-color $blockGreen
  font-family $robotoRegular
  font-size x(20)
  color $white

.masterFoundSection
  margin-bottom x(0)

.masterBlock
  display flex
  flex-flow row nowrap
  justify-content center
  align-items center
  margin-bottom x(21)

  .masterPhoto
    object-fit contain
    border-radius x(50)
    margin-right x(8)
    min-width x(70)
    width @min-width
    height @width

    &:not([src])
      border x(1) solid $black

  .masterName
    font-family $helvetica
    font-weight bold
    font-size x(18)
    line-height x(22)
    color $textGray

  .masterComment
    font-family $robotoRegular
    font-size x(14)
    line-height x(20)


.timeSelectorModal
  max-width x(345)
  width 100%
  padding-left x(30)
  padding-right @padding-left
  padding-bottom @padding-left

  .callButton + .callButton
    margin-top x(12)

.timeSelectorModalTitle
  margin-left x(0)
  margin-right @margin-left

.callButton
  max-width x(293)
  width 100%
  height x(60)
  border-radius x(14)
  blockCenter()
  padding x(13) x(20)
  font-family $robotoRegular
  font-size x(18)
  line-height x(24)
  margin-top x(12)

  .callIcon
    svg-color($white)
    margin-right x(12)

  .callButtonText
    flex-grow 1
    text-align left

    &.callMasterText
      padding-left x(30)

.callButton + .callButton
  margin-top x(14)

.closed
  display none

.masterNotFoundModal
  max-width x(345)
  width 100%
  padding-left x(30)
  padding-right @padding-left
  padding-bottom @padding-left

.masterNotFoundModalTitle
  margin-left x(0)
  margin-right @margin-left
  width 100%
  font-family $robotoLight
  font-size x(20)
  line-height x(29)
  color $textGray
  margin-bottom x(10)

.masterNotFoundModalText
  width 100%
  font-family $robotoLight
  font-size x(20)
  line-height x(29)
  color $textGray
  margin-bottom x(10)

.masterNotFoundModalButton
  button()

  .callIcon
    svg-color($white)
    margin-right x(12)

.callMasterButton
  blockCenter()

.linkHome
  text-decoration none
  font-size x(20)
  text-align center
  border-radius x(0)
  margin-top x(35)
  margin-bottom x(20)

.wrap
  display flex
  justify-content center
  align-items center
  flex-flow column
  margin-top auto
  margin-bottom @margin-top
  width 100%
  padding-left 10%
  padding-right @padding-left

.orderDelayedText
  font-size x(18)
  line-height x(24)
  margin-top x(20)
  margin-bottom: x(20)

.attention
  margin-top x(32)
