.paragraph {
  margin-bottom: 6.6rem;
}
.highlighted {
  font-style: italic;
  color: #63b896;
  margin-bottom: 2.4rem;
  font-size: 1.8rem;
  line-height: 2.4rem;
}
.listItem {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 2.2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.listItem:last-child {
  margin-bottom: 0;
}
.listItem .itemText {
  display: inline-block;
}
.listItem .listNumber {
  color: #63b896;
  font-size: 2rem;
  line-height: 2.2rem;
  width: 4rem;
  flex-shrink: 0;
}
.title {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  margin-bottom: 4rem;
}
.subtitle {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 2.2rem;
}
@media only screen and (max-width: 736px) {
  .listItem .itemText {
    width: 100%;
  }
}
/*# sourceMappingURL=app/Elements/ParagraphBlock/index.css.map */