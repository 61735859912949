
.container
  position relative
  display flex
  flex-direction row
  justify-content flex-start
  align-items flex-start

.input
  position relative
  display flex
  flex-grow 1
  flex-shrink 0
  margin 1px 2px 3px
  opacity 0
  overflow hidden
  width 19px
  height 19px
  cursor pointer

  &:disabled
    cursor default

  &:disabled + .iconWrap
    opacity .3

  &:disabled ~ .label
    cursor default
    opacity .3
  &:focus + .iconWrap
    opacity: 1
    box-shadow 0 0 1px 1xp $strokeGray

  &:checked + .iconWrap .icon
    opacity 1

.iconWrap
  pointer-events none
  position absolute
  display flex
  flex-grow 1
  justify-content center
  align-items center
  width 20px
  height 20px
  background-color $white
  border 1px solid $hintGray
  border-radius 2px

.iconWrapError
  border 2px solid $errorRed

.icon
  opacity 0
  svg-fill($pressedGreen)

.label
  cursor pointer
  font-family $robotoRegular
  font-size 14px
  color $textGray
  line-height 16px
  margin-left 14px
