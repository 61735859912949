.wrap {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background-color: #fff;
}
.wrap .content {
  max-width: 98.9rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
}
.p1 {
  margin-top: 11.6rem;
  margin-bottom: 0;
  text-align: center;
}
.form {
  margin-top: 2.7rem;
  margin-bottom: 2.7rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.form :global(.react-datepicker-wrapper) {
  display: block;
}
.form :global(.react-datepicker__input-container) {
  display: block;
}
.form :global(.react-datepicker__input-container) {
  margin-top: 0.5rem;
}
.form .inputLabel {
  font-size: 1.6rem;
  margin-left: 0.7rem;
}
.form .inputError {
  color: #d0021b;
  font-size: 1.4rem;
  font-weight: bold;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  white-space: pre-wrap;
}
.form .inputRow {
  margin-bottom: 2.9rem;
}
.form .inputColumn {
  width: 45%;
  margin-bottom: 2.9rem;
}
.form .inputColumn:nth-child(odd) {
  margin-right: 2.5%;
}
.form .inputColumn:nth-child(even) {
  margin-left: 2.5%;
}
.form .inputWrap {
  margin-top: 0.5rem;
  display: inline-flex;
}
.form .input {
  border: 0.1rem solid #d8d8d8;
  border-radius: 0.4rem;
  height: 4.5rem;
  padding: 0.7rem;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.6rem;
  line-height: 3.1rem;
}
.form .textarea {
  border: 0.1rem solid #d8d8d8;
  border-radius: 0.4rem;
  padding: 0.7rem;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.6rem;
  resize: none;
}
.form .radioButtons {
  display: flex;
  margin-left: 0.7rem;
  margin-top: 0.9rem;
}
.form .radioButtons label {
  margin-right: 1.8rem;
}
.form .inputTitle {
  margin-left: 0.7rem;
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
}
.form .isAgreeCheckbox {
  justify-content: flex-start;
  align-items: center;
}
.form .isAgreeCheckbox > div,
.form .isAgreeCheckbox > input {
  flex-grow: 0;
}
.form .isAgreeCheckbox > div {
  top: 1px;
  left: 1px;
}
.form .isAgreeCheckbox > label {
  flex: 1;
  margin-left: 1.1rem;
}
.form .button {
  width: 19.8rem;
  height: 4.8rem;
  border-radius: 0.4rem;
  align-self: flex-start;
  margin: 0;
}
.form .buttonText {
  font-size: 1.8rem;
  line-height: 2.2rem;
}
.ageMessage {
  margin-top: 2rem;
  text-align: center;
  margin-bottom: 2.8rem;
}
.ageButton {
  margin-bottom: 1.8rem;
}
@media only screen and (max-width: 736px) {
  .wrap .content {
    width: 86%;
  }
  .p1 {
    margin-top: 7.5rem;
  }
  .form {
    flex-direction: column;
  }
  .form .inputColumn {
    width: 100%;
    margin: 0 !important;
  }
  .form .button {
    width: 100%;
  }
}
@media only screen and (min-width: 737px) and (max-width: 1023px) {
  .wrap .content {
    width: 80%;
  }
  .p1 {
    margin-top: 7.5rem;
  }
  .form {
    flex-direction: column;
  }
  .form .inputColumn {
    width: 100%;
    margin: 0 !important;
  }
  .form .button {
    width: 100%;
  }
}
/*# sourceMappingURL=app/Pages/MastersRequest/index.css.map */