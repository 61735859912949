.listgroupitem
  padding x(10) x(5)

.listgroupitem:first-child
  border-top-left-radius inherit
  border-top-right-radius  inherit

.listgroupitem:last-child
  border-bottom-left-radius inherit
  border-bottom-right-radius inherit


