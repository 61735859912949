.block {
  margin-top: 7rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 4rem;
  font-size: 16px;
  line-height: 2.4rem;
}
.buttons {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.buttons .buttonsRow {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.buttons .buttonsRow:first-child {
  margin-bottom: 3.9rem;
}
.buttons .button {
  width: 4.9rem;
  height: 4.9rem;
  margin-left: 2.9rem;
}
.buttons .button img {
  width: 100%;
  height: 100%;
}
.buttons .button:first-child {
  margin-left: 0;
}
/*# sourceMappingURL=app/Components/ShareBlock/index.css.map */