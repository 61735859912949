.main {
  height: auto;
  display: flex;
  flex-direction: column;
}
.mainFull {
  height: auto;
  min-height: 100%;
}
.wrap {
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: 40rem;
  width: 100%;
  flex: 1;
  min-height: 99.4vh;
}
.wrap .footerImg {
  max-width: 40rem;
  width: 100%;
}
.wrapFull {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 0rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
.topline {
  width: 100%;
  height: 0.4rem;
  background-color: #63b896;
}
.icon {
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
}
.icon use,
.icon path,
.icon polygon {
  fill: #000;
}
.topMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 128rem;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 4rem 2rem 4.2rem;
  height: 12.8rem;
}
.topMenu .leftPart {
  display: inline-flex;
  align-items: center;
}
.topMenu .leftPart .logoWrap {
  flex-shrink: 0;
}
.topMenu .leftPart .logo {
  width: 7.8rem;
  height: 7.8rem;
}
.topMenu .leftPart .logoText {
  color: #63b896;
  max-width: 16rem;
  font-size: 1.8rem;
  margin-left: 2.6rem;
  font-style: italic;
}
.topMenu .leftPart .logoTextPhone {
  text-align: center;
  width: 17rem;
  background-color: #f5a623;
  color: #fff;
  font-size: 1.8rem;
  margin-top: 0.5rem;
  font-style: italic;
}
.topMenu .rightPart {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.topMenu .menuPart {
  display: none;
}
.menuButtonWrap {
  margin-right: 1rem;
}
.menuButtonWrap:last-child {
  margin-right: 0;
}
.menuButton {
  height: 3.8rem;
  width: 11rem;
  border: 0.15rem solid #f5a623;
  color: #444;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  border-radius: 2.3rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.menuButton:hover,
.menuButton.active {
  background-color: #f5a623;
  color: #fff;
}
.topMenuLine {
  border-bottom: 0.7rem solid #4a4a4a;
}
@media only screen and (max-width: 736px) {
  .topMenu {
    padding: 2.1rem 5% 2.2rem 5%;
    height: 12.1rem;
  }
  .topMenu .rightPart {
    display: none;
  }
  .topMenu .menuPart {
    margin-left: 5%;
    display: inline-flex;
  }
}
@media only screen and (min-width: 737px) and (max-width: 1023px) {
  .topMenu {
    padding: 2.1rem 5% 2.2rem 5%;
    height: 12.1rem;
  }
  .topMenu .rightPart {
    display: none;
  }
  .topMenu .menuPart {
    margin-left: 5%;
    display: inline-flex;
  }
}
/*# sourceMappingURL=app/Layout/OrderWrap/index.css.map */