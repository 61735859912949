.wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #191919;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5a623;
  font-family: 'Roboto Medium', sans-serif;
  color: #fff;
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  margin-right: 1.2rem;
  border-radius: 2.5rem;
}
/*# sourceMappingURL=app/Components/Attention/index.css.map */