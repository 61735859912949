.wrapper {
  display: inline-block;
}
.loader {
  margin: 0;
  width: 3.2rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.loader.big {
  width: 6.4rem;
}
.ball {
  width: 0.8rem;
  height: 0.8rem;
  background-color: #f5a623;
  border-radius: 100%;
  display: block;
  animation: bounce 1.4s ease-in-out 0s infinite both;
}
.ball.big {
  width: 1.6rem;
  height: 1.6rem;
}
.first {
  animation-delay: -0.32s;
}
.second {
  animation-delay: -0.16s;
}
.third {
  animation-delay: 0s;
}
@-moz-keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0.1);
  }
  40% {
    transform: scale(1);
  }
}
@-webkit-keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0.1);
  }
  40% {
    transform: scale(1);
  }
}
@-o-keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0.1);
  }
  40% {
    transform: scale(1);
  }
}
@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0.1);
  }
  40% {
    transform: scale(1);
  }
}
/*# sourceMappingURL=app/Elements/Loader/index.css.map */