.wrap {
  height: 100%;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.header {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 2.7rem;
}
.arrowLeftIcon {
  position: absolute;
  cursor: pointer;
  height: 5rem;
  width: 5rem;
  left: -1.6rem;
  top: 1.5rem;
}
.arrowLeftIcon use,
.arrowLeftIcon path,
.arrowLeftIcon polygon {
  fill: #000;
}
.arrowLeftIcon:hover use,
.arrowLeftIcon:hover path,
.arrowLeftIcon:hover polygon {
  fill: #6ac0ae;
}
.arrowLeftIcon:active use,
.arrowLeftIcon:active path,
.arrowLeftIcon:active polygon {
  fill: #63b896;
}
.orderNum {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 2.3rem;
  line-height: 2.7rem;
  color: #f5a623;
  margin: 0rem;
}
.orderTitle {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 2.3rem;
  line-height: 2.7rem;
  color: #f5a623;
  margin: 0rem;
  margin-bottom: 1.8rem;
}
.orderType {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 1.4rem;
  margin-bottom: 1.2rem;
  margin-top: 0.7rem;
  text-align: center;
}
.menuPart {
  position: absolute;
  right: 0;
  display: flex;
}
/*# sourceMappingURL=app/Layout/OrderInfoWrap/index.css.map */