.block
  margin-top x(70)
  margin-bottom x(30)
  display flex
  flex-direction column
  align-items stretch
  justify-content flex-start

.title
  font-weight bold
  text-align center
  margin-bottom x(40)
  font-size 16px
  line-height x(24)

.buttons
  display inline-flex
  flex-direction column
  align-items center
  justify-content center

  .buttonsRow
    display inline-flex
    flex-direction row
    align-items center
    justify-content space-between

    &:first-child
      margin-bottom x(39)

  .button
    width x(49)
    height x(49)
    margin-left x(29)

    img
      width 100%
      height 100%

    &:first-child
      margin-left 0