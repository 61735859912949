.wrapper {
  text-align: center;
  padding-top: 7.2rem;
}
.icon {
  margin-bottom: 3rem;
  display: inline-block;
}
.icon use,
.icon path,
.icon polygon {
  fill: #f5a623;
}
.title {
  margin: 0 0 2rem;
}
.title span {
  display: block;
}
.titleHeading {
  font-size: 4.8rem;
  font-weight: 600;
  line-height: 3rem;
  margin-bottom: 4.5rem;
}
.titleDesc {
  font-weight: 500;
}
.desc {
  display: block;
  font-size: 1.6rem;
  line-height: 2.2rem;
}
/*# sourceMappingURL=app/PagesXXX/404/index.css.map */