.textless {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  text-overflow: ellipsis;
  margin: 0;
/* autoprefixer: off */
}
.textmore {
  margin: 0;
}
.toggle {
  margin: 0;
  padding: 0;
  border: 0 !important;
}
.toggle .toggleContent {
  display: block;
  color: #000 !important;
  font-weight: bold;
  width: revert;
  height: revert;
}
/*# sourceMappingURL=app/Elements/Subtext/index.css.map */