@keyframes background-color {
  from {
    background-color alpha($modalGray, 0)
  }
  to {
    background-color alpha($modalGray, 0.5)
  }
}

.modalBack
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  background-color alpha($modalGray, 0.5)
  animation background-color 0.2s 1 ease-in-out
  animation-fill-mode forwards

.modal
  position absolute
  top 20%
  left 50%
  transform translate(-50%, -17%)
  opacity 1
  background-color $white
  border-radius x(12)
  box-shadow 0 0 x(10) 0 alpha(#396571, 0.2), 0 x(1) x(1) 0 alpha(#396571, 0.05)

.iconContainer
  cursor pointer
  position absolute
  top x(15)
  right x(15)
  opacity 1
  transition opacity ease 0.2s
  background-color: $black
  width: x(24)
  height: x(24)
  border-radius: x(15)
  display: grid
  justify-content center
  align-items: center
  &:hover
    opacity 0.6

.iconStyle
  svg-fill($white) 

.title
  font-family $robotoLight
  font-size x(20)
  font-weight 500
  line-height x(29)
  color $textGray
  margin-bottom 0
  margin-left x(30)
  margin-right x(65)
  margin-top x(20)
