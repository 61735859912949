.roubleIcon
  display inline-flex
  width x(24)
  height x(24)

.timerButton
  width 100%
  height x(60)
  blockCenter()
  margin-bottom x(24)
  max-width x(300)
  border-radius x(14)
  padding x(24) x(17)
  font-family $robotoLight
  font-size x(19)  

.timeIcon
  svg-fill($accentOrange)
  width x(30)
  height x(30)
  min-width x(30)
  margin-right x(8)

.totalPrice
  margin-left x(8)
  margin-top x(0)
  margin-right x(-3)
  font-family $robotoMedium
  width x(100)
  text-align right   

  .roubleIcon
    margin-bottom x(-5)

.dropdownIcon
  width x(40)
  height x(40)
  min-width x(40)

.totalDropdownIcon
  svg-fill($black)

.orderTable
  font-family $robotoLight
  font-size x(12)
  max-width x(290)
  blockCenter()
  display grid
  grid-template-columns 3fr 1fr 2fr

  .right
    text-align right

  .total
    font-family $robotoMedium
    font-size x(16)

    .roubleIcon
      margin-bottom x(-5)

  .accent
    .roubleIcon
      margin-bottom x(-4)
      width x(18)
      height x(18)
      min-width x(18)

.accent
  font-family $robotoMedium

.label
  font-family $robotoLight
  font-size x(10)

.line
  grid-column 1 / 4
  margin-top x(10)
  margin-bottom @margin-top
  border-bottom x(1) solid $strokeGray

.closed
  display none
