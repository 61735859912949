.waitingForPay {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}
.map {
  max-height: 35.7rem;
  max-width: 40rem;
  width: auto;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.searchMetaBlock {
  width: auto;
  text-align: center;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  background-color: #50b58d;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 2rem;
  color: #fff;
}
.masterFoundSection {
  margin-bottom: 0rem;
}
.masterBlock {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.1rem;
}
.masterBlock .masterPhoto {
  object-fit: contain;
  border-radius: 5rem;
  margin-right: 0.8rem;
  min-width: 7rem;
  width: 7rem;
  height: 7rem;
}
.masterBlock .masterPhoto:not([src]) {
  border: 0.1rem solid #000;
}
.masterBlock .masterName {
  font-family: 'Helvetica Neue', Helvetica Arial, sans-serif;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #191919;
}
.masterBlock .masterComment {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.4rem;
  line-height: 2rem;
}
.timeSelectorModal {
  max-width: 34.5rem;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
}
.timeSelectorModal .callButton + .callButton {
  margin-top: 1.2rem;
}
.timeSelectorModalTitle {
  margin-left: 0rem;
  margin-right: 0rem;
}
.callButton {
  max-width: 29.3rem;
  width: 100%;
  height: 6rem;
  border-radius: 1.4rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 1.3rem 2rem;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-top: 1.2rem;
}
.callButton .callIcon {
  margin-right: 1.2rem;
}
.callButton .callIcon use,
.callButton .callIcon path,
.callButton .callIcon polygon {
  fill: #fff;
}
.callButton .callIcon use,
.callButton .callIcon path,
.callButton .callIcon polygon {
  stroke: #fff;
}
.callButton .callButtonText {
  flex-grow: 1;
  text-align: left;
}
.callButton .callButtonText.callMasterText {
  padding-left: 3rem;
}
.callButton + .callButton {
  margin-top: 1.4rem;
}
.closed {
  display: none;
}
.masterNotFoundModal {
  max-width: 34.5rem;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
}
.masterNotFoundModalTitle {
  margin-left: 0rem;
  margin-right: 0rem;
  width: 100%;
  font-family: 'Roboto Light', sans-serif;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #191919;
  margin-bottom: 1rem;
}
.masterNotFoundModalText {
  width: 100%;
  font-family: 'Roboto Light', sans-serif;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #191919;
  margin-bottom: 1rem;
}
.masterNotFoundModalButton {
  font-family: 'Roboto Regular', sans-serif;
  color: #fff;
  font-size: 2rem;
  line-height: 2.4rem;
  max-width: 29.3rem;
  width: 100%;
  height: 6rem;
  border-radius: 1.4rem;
  margin-top: 1.2rem;
}
.masterNotFoundModalButton .callIcon {
  margin-right: 1.2rem;
}
.masterNotFoundModalButton .callIcon use,
.masterNotFoundModalButton .callIcon path,
.masterNotFoundModalButton .callIcon polygon {
  fill: #fff;
}
.masterNotFoundModalButton .callIcon use,
.masterNotFoundModalButton .callIcon path,
.masterNotFoundModalButton .callIcon polygon {
  stroke: #fff;
}
.callMasterButton {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.linkHome {
  text-decoration: none;
  font-size: 2rem;
  text-align: center;
  border-radius: 0rem;
  margin-top: 3.5rem;
  margin-bottom: 2rem;
}
.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}
.orderDelayedText {
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.attention {
  margin-top: 3.2rem;
}
/*# sourceMappingURL=app/Pages/Order/OrderHot/index.css.map */