.wrap {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background-color: #fff;
}
.wrap .content {
  max-width: 98.9rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
}
.titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4rem;
}
.titleRow .title {
  font-size: 1.6rem;
  font-weight: bold;
  margin-left: 2.4rem;
}
.titleRow .titleIcon use,
.titleRow .titleIcon path,
.titleRow .titleIcon polygon {
  fill: #f5a623;
}
.linksWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.linkRow {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 58rem;
}
.linkRow + .linkRow {
  margin-top: 2.1rem;
}
.linkRow .linkIcon {
  margin-right: 2.7rem;
  flex-shrink: 0;
}
.linkRow .linkIcon use,
.linkRow .linkIcon path,
.linkRow .linkIcon polygon {
  fill: #63b896;
}
.linkRow:hover .linkText {
  color: #63b896;
}
.linkRow .linkText {
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-top: 0.2rem;
  color: #000;
}
.p1 {
  margin-top: 8.8rem;
  margin-bottom: 8.8rem;
  margin-left: 12rem;
  min-height: calc(100vh - 44.5rem);
}
@media only screen and (max-width: 736px) {
  .wrap .content {
    width: 86%;
  }
  .p1 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
    margin-left: 0;
  }
}
@media only screen and (min-width: 737px) and (max-width: 1023px) {
  .wrap .content {
    width: 80%;
  }
  .p1 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
    margin-left: 0;
    min-height: calc(100vh - 50.8rem);
  }
}
/*# sourceMappingURL=app/Pages/Codex/index.css.map */