.link {
  cursor: pointer;
  border-radius: 0.8rem;
  display: inline-block;
  outline: none;
  text-decoration: none;
  background: transparent;
}
.link.orange {
  color: #f5a623;
}
.link.orange.underlined {
  border-bottom-color: #f5a623;
}
.link.orange:hover {
  color: #ffc86e;
}
.link.orange:hover.underlined {
  border-bottom-color: #ffc86e;
}
.link.orange:active {
  color: #ff9200;
}
.link.orange:active.underlined {
  border-bottom-color: #ff9200;
}
.link.green {
  color: #63b896;
}
.link.green:hover {
  color: #6ac0ae;
}
.link.green:hover.underlined {
  border-bottom-color: #6ac0ae;
}
.link.green:active {
  color: #239a6a;
}
.link.green:active.underlined {
  border-bottom-color: #239a6a;
}
.link.dark {
  color: #191919;
}
.link.dark:hover {
  color: #6ac0ae;
}
.link.dark:hover.underlined {
  border-bottom-color: #6ac0ae;
}
.link.dark:active {
  color: #239a6a;
}
.link.dark:active.underlined {
  border-bottom-color: #239a6a;
}
.link.white {
  color: #fff;
}
.link.white:hover {
  color: #fff;
}
.link.white:hover.underlined {
  border-bottom-color: #fff;
}
.link.white:active {
  color: #fff;
}
.link.white:active.underlined {
  border-bottom-color: #fff;
}
.underlined {
  border-radius: 0rem;
  border-bottom: 0.2rem solid;
}
.anchor {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.anchor .number {
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  margin-right: 1.2rem;
}
/*# sourceMappingURL=app/Elements/SmartLink/index.css.map */