.wrap {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background-color: #fff;
}
.wrap .content {
  width: 100%;
  max-width: 50rem;
  padding-top: 9.5rem;
  padding-bottom: 12rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;
  flex-grow: 1;
  min-height: calc(100vh - 26.9rem);
}
.wrap .content .button {
  width: 19.8rem;
  height: 4.8rem;
  border-radius: 0.4rem;
  align-self: flex-start;
  margin: 0;
}
.wrap .content .buttonText {
  font-size: 1.8rem;
  line-height: 2.2rem;
}
.wrap .content .form {
  margin-top: 2rem;
  padding: 1rem;
}
.wrap .content .inputLabel {
  font-size: 1.6rem;
  margin-left: 0.7rem;
}
.wrap .content .inputError {
  color: #d0021b;
  font-size: 1.4rem;
  margin-left: 0.7rem;
}
.wrap .content .inputRow {
  margin-bottom: 2.9rem;
}
.wrap .content .inputWrap {
  margin-top: 0.5rem;
}
.wrap .content .input {
  border: 0.1rem solid #d8d8d8;
  border-radius: 0.4rem;
  height: 4.5rem;
  padding: 0.7rem;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1.6rem;
  line-height: 3.1rem;
}
.wrap .content .input.textarea {
  height: 10.2rem;
  resize: none;
}
.wrap .content .infoText {
  font-size: 2rem;
  line-height: 2.7rem;
  color: #000;
  font-weight: 500;
  display: block;
  align-self: flex-start;
  padding: 1rem;
}
@media only screen and (max-width: 736px) {
  .wrap .content {
    flex-direction: column;
    padding-top: 7.5rem;
  }
  .wrap .content .button {
    width: 100%;
  }
}
@media only screen and (min-width: 737px) and (max-width: 1023px) {
  .wrap .content {
    flex-direction: column;
    padding-top: 7.5rem;
  }
  .wrap .content .button {
    width: 100%;
  }
}
/*# sourceMappingURL=app/Pages/OrderTerminate/index.css.map */