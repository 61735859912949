.wrap
  display flex
  flex-direction column
  align-items stretch
  justify-content flex-start
  height 100%
  background-color white

  .content
    max-width x(989)
    margin 0 auto
    display flex
    flex-direction column
    align-items stretch
    justify-content flex-start
    flex-grow 1

    +media('phone')
      width 86%

    +media('tablet')
      width 80%

.p1
  margin-top x(116)

  +media('phone')
    margin-top x(75)

  +media('tablet')
    margin-top x(75)


.video
  margin-bottom x(64)
  height x(500)

  +media('tablet')
    height x(400)

  +media('phone')
    height x(250)


applyMediaCache()
