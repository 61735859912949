.substrate {
  background-color: $transparent;
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100%;
  height: 100%;
  z-index: 998;
}
.list {
  background-color: #fff;
  position: absolute;
  margin-top: 0.3rem;
  z-index: 999;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  width: 37rem;
}
.item {
  padding: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.city {
  color: #d5d5d5;
}
/*# sourceMappingURL=app/Elements/DropDownListStreet/index.css.map */